<template lang='haml'>
  .badge-container(v-if="completedApps.length > 0")
    / First & Last Course
    %template(v-for="type in ['first', 'last']")
      / fake v-for in order to save the result of the method
      .card.full-width-sm(v-for="badgeApp in [firstLastApp(type)]")
        .card-body
          %h5.mb-2
            %template(v-if="type =='first'") {{ $t(`activerecord.attributes.course_application.first_course`) }}
            %template(v-if="type =='last'") {{ $t(`activerecord.attributes.course_application.last_course`) }}
          .content
            %div(v-if="badgeApp.start_date")
              {{ badgeApp.start_date.toLocaleDateString(locale, {month: 'short', year: "2-digit" }) }}
            %div {{ badgeApp.venue }}
            %div {{ badgeApp.teacher ? badgeApp.teacher.name : '' }}

    / All courses summary
    .card.all-courses.full-width-sm
      .card-body
        .count {{ completedAppsCount }}
        .label {{ $t('comm.course_applications.total_courses') }}
        .btn.btn-sm.btn-outline-primary.mt-2(@click="displayDetails = !displayDetails")
          {{ $t('comm.commons.actions.view_details')}}

    / All courses details
    .card.all-courses-details.bg-light.full-width-sm(v-show="displayDetails")
      .card-body.row
        .col-md-6(v-if="satBadges.length > 0 || otherCoursesSat")
          %h5 {{ $t('comm.course_applications.headings.courses_sat')}}
          / Courses Sat
          .detail-section(v-if="satBadges.length > 0")
            .detail-item(v-for="item in satBadges")
              %span.count.fw-semibold.me-2 {{ item.count }}
              %span.label {{ item.label }}
          %h5.mt-3(v-if="satBadges.length > 0 && otherCoursesSat")
            {{ $t("activerecord.attributes.course_application.other_courses_sat") }}
          / Other Courses Sat
          .detail-item(v-if="otherCoursesSat") {{ otherCoursesSat }}

        .col-md-6(v-if="satBadges.length > 0 || otherCoursesServed")
          %h5.mt-3.mt-md-0 {{ $t('comm.course_applications.headings.courses_served')}}
          / Courses Served
          .detail-section(v-if="satBadges.length > 0")
            .detail-item(v-for="item in servedBadges")
              %span.count.fw-semibold.me-2 {{ item.count }}
              %span.label {{ item.label }}
          %h5.mt-3(v-if="servedBadges.length > 0 && otherCoursesServed")
            {{ $t("activerecord.attributes.course_application.other_courses_served") }}
          / Other Courses Served
          .detail-item(v-if="otherCoursesServed") {{ otherCoursesServed }}
</template>

<script>
export default {
  data() {
    return {
      displayDetails: false,
      eventBadgeTypes: [
        'TenDayCourse', 'SatipatthanaCourse', 'TenDaySpecialCourse', 'GratitudeCourse', 'TscTenDayCourse',
        'LongCourse20', 'LongCourse30', 'LongCourse45', 'LongCourse60'],
      other10dayTypes: ['TenDayCourse', 'TenDayExecutiveCourse', 'TenDayOldStudentCourse'],
      satBadges: [],
      servedBadges: [],
      otherCoursesSat: '',
      otherCoursesServed: ''
    }
  },
  computed: {
    completedApps() {
      return this.$root.apps.filter((app) => app.conf_state == 'Completed')
        .sort((a, b) => a.start_date - b.start_date)
    },
    completedAppsCount() {
      let count = 0
      this.completedApps.forEach((app) => { count += app.is_batch ? parseInt(app.batch_count, 10) : 1 })
      return count
    }
  },
  methods: {
    appsFor(eventType, participation) {
      let types = [eventType]
      const participations = [participation]
      if (eventType == 'TenDayCourse') types = this.other10dayTypes
      if (participation == 'old_student_ft') participations.push('new_student')
      const apps = this.completedApps.filter((app) => types.includes(app.event_type)
                                                   && participations.includes(app.participation))
      let count = 0
      apps.forEach((app) => { count += app.is_batch ? parseInt(app.batch_count, 10) : 1 })
      return [apps, count]
    },
    firstLastApp(type) {
      const apps = this.completedApps.filter((app) => !app.participation.includes('server') && !app.is_batch)
      if (apps.length == 0) return null
      return type == 'last' ? apps.slice(-1)[0] : apps[0]
    },
    calculateBadgeApps() {
      this.satBadges = this.calculateBadgeAppsFor('old_student_ft')
      this.servedBadges = this.calculateBadgeAppsFor('server_ft')
      this.otherCoursesSat = this.calculateOtherCoursesFor('old_student_ft')
      this.otherCoursesServed = this.calculateOtherCoursesFor('server_ft')
    },
    calculateBadgeAppsFor(participation) {
      const result = []
      this.eventBadgeTypes.forEach((eventType) => {
        const [apps, count] = this.appsFor(eventType, participation)
        if (count > 0) {
          result.push({ count, label: this.$root.translateEventType(apps[0].event_type) })
        }
      })
      return result
    },
    calculateOtherCoursesFor(participation) {
      const result = []
      this.$root.eventTypesForSelect.forEach(([eventLabel, eventType]) => {
        if (!this.eventBadgeTypes.concat(this.other10dayTypes).includes(eventType)) {
          const [apps, count] = this.appsFor(eventType, participation)
          if (count) result.push(`${count} x ${eventLabel}`)
        }
      })
      return result.join(', ')
    }
  },
  mounted() {
    this.calculateBadgeApps()
    this.$watch(
      function() { return this.$root.apps }, // when to trigger the watch
      function() { this.calculateBadgeApps() } // what to do upon trigger
    )
  }
}
</script>

<style lang='scss'>
  .badge-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 2rem;
    justify-content: space-between;
    gap: 1.5rem;

    h5 {
      font-size: 1.25rem;
    }

    .card {
      width: 100%;
      @media (max-width: 768px) {
        .content {
          display: flex;
          div:not(:last-child) {
            margin-right: .25rem;
            &:after { content: ","; }
          }
        }
      }
      @media (min-width: 769px) {
        &:not(.all-courses-details) {
          width: calc((100% - 3rem) / 3);
          text-align: center;
        }
      }
    }

    .all-courses {
      @media (max-width: 768px) {
        .card-body {
          display: flex;
          align-items: flex-end;
          padding-top: 0.5rem !important;
        }
        .count {
          font-size: 2rem;
          line-height: 2rem;
        }
        .label {
          margin-left: .4rem;
          margin-right: auto;
        }
        .btn {
          align-self: center;
        }
      }
      @media (min-width: 769px) {
        .count {
          font-size: 2.5rem;
          line-height: 2.5rem;
        }
      }
    }
  }
</style>
