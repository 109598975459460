import { snakeCase } from 'helpers/string_helper'
import CrudMixin from 'mvip/helpers/crud-mixin'
import CourseHistoryBadges from './CourseHistoryBadges.vue'
import CourseHistory from './CourseHistory.vue'

export default {
  mixins: [CrudMixin],
  components: { CourseHistoryBadges, CourseHistory },
  data: {
    apps: [],
    eventTypesForSelect: [],
    otherServiceEdited: false
  },
  mounted() {
    this.apps = gon.course_applications.map((app) => {
      // Add T00:00:00Z to force UTC timezone Refs #5828
      if (app.start_date) app.start_date = new Date(`${app.start_date}T00:00:00Z`)
      if (app.end_date) app.end_date = new Date(`${app.end_date}T00:00:00Z`)
      return app
    })

    this.eventTypesForSelect = gon.event_types_for_select.map((event) => [this.translateEventType(event), event])

    // Init CRUD Mixin
    this.crud.apps = {
      route: 'mvip_my_application',
      params: {},
      label: this.$t('activerecord.models.course_application.one')
    }
  },
  methods: {
    translateEventType(eventType) {
      return this.$t(`lookups.event_type.short.${snakeCase(eventType)}`)
    }
  }
}
