import { render, staticRenderFns } from "./PersonLanguages.vue?vue&type=template&id=80744fa6&scoped=true&lang=haml&"
import script from "./PersonLanguages.vue?vue&type=script&lang=js&"
export * from "./PersonLanguages.vue?vue&type=script&lang=js&"
import style0 from "./PersonLanguages.vue?vue&type=style&index=0&id=80744fa6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "80744fa6",
  null
  
)

export default component.exports