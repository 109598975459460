<template lang='haml'>
  %vue-tel-input(v-bind="$attrs" v-bind:value="internalValue" :validCharactersOnly="true"
                 @validate="onValidateChange" @input="onInput" v-on="$listeners" ref="input"
                 mode="international" :input-options="{placeholder: '', name: $attrs.name}")
    %template(v-slot:arrow-icon)
      %svg.arrow-icon(height="10" role="presentation" width="14" xmlns="http://www.w3.org/2000/svg")
        %path(d="M9.211364 7.59931l4.48338-4.867229c.407008-.441854.407008-1.158247 0-1.60046l-.73712-.80023c-.407008-.441854-1.066904-.441854-1.474243 0L7 5.198617 2.51662.33139c-.407008-.441853-1.066904-.441853-1.474243 0l-.737121.80023c-.407008.441854-.407008 1.158248 0 1.600461l4.48338 4.867228L7 10l2.211364-2.40069z")
    %template(v-slot:icon-right)
      .input-group-text.error(v-if="!isValid") {{ $t('comm.lookups.errors.invalid') }}
</template>

<script>
import VueTelInput from 'vue-tel-input'
import 'vue-tel-input/dist/css/sprite.css' // Flags styles
import Vue from 'vue'

Vue.use(VueTelInput)
export default {
  props: ['value'],
  data() {
    return {
      initialValue: undefined,
      internalValue: '',
      isValid: true
    }
  },
  mounted() {
    // auto detect country conflicts with existing value, so doing some small trick
    setTimeout(() => { this.internalValue = this.initialValue }, 500)

    // Style input with bootstrap classes
    this.$refs.input.$el.classList.add('input-group')
    this.$el.querySelector('.vti__input').classList.add('form-control')
    // this.$el.querySelector('.vti__dropdown').classList.add('input-group-text')
  },
  methods: {
    onInput(val) {
      this.internalValue = val
      this.$emit('input', val)
    },
    onValidateChange(phoneObject) {
      if (phoneObject.valid !== undefined) this.isValid = phoneObject.valid
    }
  },
  watch: {
    value(newVal) {
      if (!this.initialValue) this.initialValue = newVal
    }
  }
}
</script>

<style lang='scss'>
  .vue-tel-input {
    .vti__dropdown {
      &:focus { outline: none; }
      cursor: pointer;
      background-color: transparent;

      // mimics .input-group-text
      display: flex;
      align-items: center;
      padding: var(--bs-input-padding);
      border: 1px solid var(--bs-border-color);
      border-radius: var(--bs-border-radius);

      .vti__selection {
        display: flex;
        align-items: center;

        .arrow-icon {
          fill: rgba(60, 60, 60, 0.5);
          transform: scale(0.7);
          margin-left: 5px;
        }
      }
    }
    .input-group-text.error {
      background-color: transparent;
      color: var(--bs-danger);
    }
    .vti__dropdown-list {
      z-index: 500;
      top: 2.3rem;;
      padding: 5px 0;
      margin: 0;
      text-align: left;
      list-style: none;
      max-height: 200px;
      overflow-y: scroll;
      overflow-x: hidden;
      position: absolute;
      left: 0;
      right: 0;
      background-color: #fff;
      border: 1px solid #ccc;
      .vti__dropdown-item {
        cursor: pointer;
        padding: 6px 15px;
        display: flex;
        align-items: center;
      }
      .vti__flag {
        display: inline-block;
        margin-right: .5rem;
      }
    }
    .vti__flag {
      transform: scale(0.85);
    }
    .last-preferred {
      border-bottom: 1px solid #ced4d9;
      padding-bottom: 8px;
      margin-bottom: 8px;
    }
  }
</style>
