<template lang='haml'>
  .checkbox-segment
    %strong.text-success(v-if="action == 'show'")
      %input.form-check-input(type="checkbox"
        :checked="segment.value"
        :disabled="segment.value")
      %span.checkbox-segment-value
      {{ displaySegmentValue(segment.value) }}
    %template(v-else)
      %input.form-check-input(type="checkbox"
        :id="segment.ident"
        :name="`form[segments][${segment.ident}]`"
        :value="value"
        :disabled="action == 'preview'"
        @input="$emit('input', $event.target.checked)"
        :required="!!segment.required")
</template>

<script>
export default {
  props: ['segment', 'action', 'value'],
  methods: {
    displaySegmentValue(segValue) {
      if (segValue == 'true') return this.$t('comm.lookups.general.yes')
      return this.$t('comm.lookups.general.no')
    }
  }
}
</script>

<style lang='scss' scoped>
  .checkbox-segment {
    margin-top: .5rem;
  }
  .checkbox-segment-value {
    padding-left: 10px;
  }
</style>
