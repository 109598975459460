import axiosLib from 'axios'

export const axios = axiosLib

export function handleAjaxError(error, vueComponent = null) {
  console.error(error)
  console.log(error.response)
  const errMsg = ''
  const calmError = error.response.data.error
  if (error.response.status === 401 && typeof (calmError) === 'string' && calmError.indexOf('action_not_permitted') === -1) {
    window.location.reload() // this is a session timeout issue so reload will trigger devise to http redirect to the sign in page
    return
  }
  if (vueComponent) {
    vueComponent.$toast.add({
      severity: 'error',
      summary: vueComponent.$t('comm.lookups.errors.title'),
      detail: calmError || vueComponent.$t('comm.lookups.errors.general')
    })
  }
}

const defaultConfig = () => ({
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content
  },
  transformResponse: [function(data) {
    // Custome parsing JSON to handle Dates and DateTimes sent by rails
    const timeFormat = /^\d{4}-\d{2}-\d{2}\s\d{2}:\d{2}:\d{2}\s\+\d{4}$/
    const timeFormat2 = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}\+\d{2}:\d{2}$/
    const timeFormat3 = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/
    const dateFormat = /^\d{4}-\d{2}-\d{2}$/
    const reviver = function(key, value) {
      if (typeof value === 'string' && (dateFormat.test(value) || timeFormat.test(value) || timeFormat2.test(value) || timeFormat3.test(value))) {
        return new Date(value)
      }
      return value
    }
    try {
      return JSON.parse(data, reviver)
    } catch (err) {
      console.log(err)
      return data
    }
  }]
})

/**
 * Send a GET request.
 *
 * @param {string} url
 * @param {object} [config={}]
 * @returns {Promise}
 */
export function get(url, config = {}) {
  return axios.get(url, Object.assign(defaultConfig(), config))
}

/**
 * Send a DELETE request.
 *
 * @param {string} url
 * @param {object} [config={}]
 * @returns {Promise}
 */
export function destroy(url, config = {}) {
  return axios.delete(url, Object.assign(defaultConfig(), config))
}

/**
 * Send a POST request.
 *
 * @param {string} url
 * @param {object} data
 * @param {object} [config={}]
 * @returns {Promise}
 */
export function post(url, data, config = {}) {
  return axios.post(url, data, Object.assign(defaultConfig(), config))
}

/**
 * Send a PUT request.
 *
 * @param {string} url
 * @param {object} data
 * @param {object} [config={}]
 * @returns {Promise}
 */
export function put(url, data, config = {}) {
  return axios.put(url, data, Object.assign(defaultConfig(), config))
}

/**
 * Send a PATCH request.
 *
 * @param {string} url
 * @param {object} data
 * @param {object} [config={}]
 * @returns {Promise}
 */
export function patch(url, data, config = {}) {
  return axios.patch(url, data, Object.assign(defaultConfig(), config))
}

export function debounce(fn, delay = 250) {
  let timeoutID = null
  return function() {
    clearTimeout(timeoutID)
    const args = arguments
    const that = this
    timeoutID = setTimeout(() => {
      fn.apply(that, args)
    }, delay)
  }
}
