<template lang='haml'>
  .messages-index
    %h2.d-flex.align-items-center.flex-wrap.mb-3
      .me-auto {{ $t("comm.lookups.sidenav.messages") }}

      / Filtering
      .ff-normal.fs-normal
        %calm-select(:options="contextOptions" v-model="selectedContext" label="label")

      / Contact Us
      %MessageActionsByContext.ff-normal.ms-2(
        :context-options="contextOptions"
        :selected-context="selectedContext"
        @contact="$refs.form.open({course_application_id: $event})")

    %MessagesList(:messages="messages" ref="msgList")

    %MessageForm(ref="form" @msg-created="$refs.msgList.open($event)")

</template>

<script>
import MessagesList from './MessagesList.vue'
import MessageForm from './MessageForm.vue'
import MessageActionsByContext from './MessagesActionsByContext.vue'

export default {
  components: { MessageForm, MessagesList, MessageActionsByContext },
  data() {
    return { selectedContext: { } }
  },
  mounted() {
    // next tick in order to wait for calm select to be initialized
    this.$nextTick(function() {
      const mycontext = gon.context || ''
      this.selectedContext = this.contextOptions.find((context) => context.value == mycontext)
      this.selectedContext ||= this.defaultContext
    })
  },
  computed: {
    defaultContext() {
      return { label: this.$t('lookups.all') }
    },
    messages() {
      return this.$root.messages.filter((msg) => {
        if (!this.selectedContext.value) return true
        if (Array.isArray(this.selectedContext.value)) return this.selectedContext.value.includes(msg.context)
        return msg.context == this.selectedContext.value
      })
    },
    // Different values in the context column (either topic (publication, rsl) or app_id if topic is course_app)
    contextOptions() {
      const contexts = []; const topicsOptions = []; let appsOptions = []
      this.$root.course_applications.forEach((app) => {
        contexts.push(app.id)
        appsOptions.push({
          label: app.display,
          value: `${app.id}`,
          type: 'app',
          courseApplication: app
        })
      })
      appsOptions = appsOptions.sort((a, b) => b.courseApplication.event_start_date - a.courseApplication.event_start_date)
      this.$root.messages.filter((msg) => !msg.app_id).forEach((msg) => {
        if (!contexts.includes(msg.context)) {
          contexts.push(msg.context)
          // some topic have same label, so we group them in order to not have multiple tabs with same name
          const existingOption = topicsOptions.some((t) => t.context_display == msg.context_display)
          if (existingOption) existingOption.value = [existingOption.value, msg.context].flat()
          else topicsOptions.push({ label: msg.context_display, value: msg.context, type: 'topic' })
        }
      })
      return [this.defaultContext].concat(appsOptions).concat(topicsOptions.sort())
    }
  },
  watch: {
    selectedContext(newVal, oldVal) {
      // update url depending on context
      let newUrl = window.location.href.includes('?') ? window.location.href.split('?')[0] : window.location.href
      if (newVal.value) newUrl += `?context=${newVal.value}`
      window.history.replaceState(window.history.state, '', newUrl)
    }
  }
}
</script>
