var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"course-history"},[_c('h2',{staticClass:"d-flex align-items-center mb-3"},[_c('div',{staticClass:"me-auto"},[_vm._v(_vm._s(_vm.$t('comm.course_applications.headings.history')))]),_c('div',{staticClass:"ff-normal"},[(_vm.screen.isSM)?[_c('div',{staticClass:"dropdown"},[_vm._m(0),_c('ul',{staticClass:"dropdown-menu"},[_c('li',[_c('a',{staticClass:"dropdown-item",on:{"click":_vm.addCustomApp}},[_vm._v(_vm._s(_vm.$t('comm.course_applications.actions.add_course')))])]),_c('li',[_c('a',{staticClass:"dropdown-item",on:{"click":_vm.addCustomAppInBatch}},[_vm._v(_vm._s(_vm.$t('comm.course_applications.actions.add_course_in_batch')))])])])])]:[_c('a',{staticClass:"btn btn-outline-secondary",on:{"click":_vm.addCustomAppInBatch}},[_c('div',{staticClass:"pi pi-plus me-1"}),_vm._v(_vm._s(_vm.$t('comm.course_applications.actions.add_course_in_batch')))]),_c('a',{staticClass:"btn btn-secondary ms-3",on:{"click":_vm.addCustomApp}},[_c('div',{staticClass:"pi pi-plus me-1"}),_vm._v(_vm._s(_vm.$t('comm.course_applications.actions.add_course')))])]],2)]),_c('Data-Table',{attrs:{"value":_vm.previousApps,"paginator":_vm.previousApps.length > 10,"rows":10,"data-key":"id","responsiveLayout":"scroll"},scopedSlots:_vm._u([{key:"empty",fn:function(undefined){return [_c('div',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t('datatable.common.no_result')))])]}}])},[(_vm.screen.isSM)?_c('Column',{attrs:{"field":"id"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [_c('span',[_vm._v(_vm._s(_vm.$root.translateEventType(data.event_type)))]),_c('span',[_vm._v(" - ")]),_c('span',{staticClass:"ms-1"},[(['Completed', 'Arrived'].includes(data.conf_state))?[_vm._v(_vm._s(_vm.$t('comm.course_applications.attrs.participation_values.' + data.participation)))]:[_vm._v(_vm._s(_vm.$t('lookups.course_app_confirmation_states.shorter_display_name.' + data.conf_state)))]],2),_c('div',{staticClass:"subtitle"},[(data.is_batch)?[_vm._v(_vm._s(_vm.$t('comm.course_applications.attrs.batch_count_description', { count: data.batch_count })))]:[(data.start_date)?_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(data.start_date.toLocaleDateString(_vm.locale, {month: 'long', year: "numeric" })))]):_vm._e(),(data.venue)?_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(data.venue.length > 1 ? ' - ' + data.venue : ''))]):_vm._e(),(data.teacher && data.teacher.name)?_c('span',[_vm._v(_vm._s(data.teacher.name.length > 1 ? ' - ' + data.teacher.name : ''))]):_vm._e()]],2)]}}],null,false,240309625)}):_vm._e(),(_vm.screen.upMD)?_c('Column',{attrs:{"field":"dates","header":_vm.$t('comm.course_applications.attrs.dates')},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [(data.start_date)?[_c('span',[_vm._v(_vm._s(data.start_date.toLocaleDateString(_vm.locale, {year: "numeric" })))]),_c('span',{staticClass:"ms-1"},[_vm._v(_vm._s(data.start_date.toLocaleDateString(_vm.locale, {month: 'short' })))])]:_vm._e()]}}],null,false,602163261)}):_vm._e(),(_vm.screen.upMD)?_c('Column',{attrs:{"field":"event_type","header":_vm.$t('comm.course_applications.attrs.event_type')},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [_vm._v(_vm._s(_vm.$root.translateEventType(data.event_type))),_c('span',{directives:[{name:"show",rawName:"v-show",value:(data.event_type != 'LongTermService'),expression:"data.event_type != 'LongTermService'"}],staticClass:"badge text-bg-light ms-1"},[(['Completed', 'Arrived'].includes(data.conf_state))?[_vm._v(_vm._s(_vm.$t('comm.course_applications.attrs.participation_values.' + data.participation)))]:[_vm._v(_vm._s(_vm.$t('lookups.course_app_confirmation_states.shorter_display_name.' + data.conf_state)))]],2)]}}],null,false,1232068362)}):_vm._e(),(_vm.screen.upMD)?_c('Column',{attrs:{"field":"infos","header":((_vm.$t('comm.course_applications.attrs.location')) + " - " + (_vm.$t('comm.course_applications.attrs.teacher')))},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [(data.is_batch)?[_vm._v(_vm._s(_vm.$t('comm.course_applications.attrs.batch_count_description', { count: data.batch_count })))]:[(data.venue)?_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(data.venue))]):_vm._e(),(data.venue && data.teacher && data.teacher.name.length > 1)?_c('span',[_vm._v(" - ")]):_vm._e(),(data.teacher && data.teacher.name)?_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(data.teacher.name))]):_vm._e()]]}}],null,false,164003777)}):_vm._e(),_c('Column',{attrs:{"header-style":"width: 6rem; flex-shrink: 0","body-class":"text-end"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [(data.editable)?_c('div',{staticClass:"btn btn-sm d-inline",on:{"click":function($event){return _vm.editApp(data)}}},[_c('i',{staticClass:"pi pi-pencil"})]):_vm._e(),(data.editable)?_c('ConfirmButton',{attrs:{"btn-class":"btn-sm d-inline","type":"danger"},on:{"confirmed":function($event){return _vm.$root.destroy('apps', data)}}},[_c('i',{staticClass:"pi pi-times"})]):_vm._e()]}}])})],1),_c('CourseHistoryForm',{ref:"form"}),_c('CourseHistoryBatchForm',{ref:"batchForm"})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"btn btn-secondary dropdown-toggle rounded-circle p-2",attrs:{"aria-expanded":"false","data-bs-toggle":"dropdown","type":"button"}},[_c('div',{staticClass:"pi pi-plus"})])}]

export { render, staticRenderFns }