<template lang='haml'>
  %button.btn(type="button" :disabled="loading" v-bind="$attrs" v-on="$listeners")
    %i.fas.fa-circle-notch.fa-spin(v-if="loading" :class="{'me-2': label}")
    %i(:class="[icon, {'me-2': label}]" v-if="icon && !loading")
    {{ label }}
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang='scss' scoped>

</style>
