const breakpoints = {
  sm: {
    min: 0,
    max: 767
  },
  md: {
    min: 768,
    max: 991
  },
  lg: {
    min: 992,
    max: 1999
  },
  xl: { min: 1200 }
}

export default {
  install(Vue, options) {
    Vue.mixin({
      data() {
        return {
          screen: {
            width: 0,
            height: 0,
            isSM: false,
            isMD: false,
            upMD: false,
            isLG: false,
            upLG: false,
            downLG: false,
            isXL: false
          }
        }
      },
      beforeMount() {
        this.getWindowWidth()
        this.getWindowHeight()
        window.addEventListener('resize', this.getWindowWidth)
        window.addEventListener('resize', this.getWindowHeight)
      },
      methods: {
        getWindowWidth(event) {
          const w = document.documentElement.clientWidth
          this.screen.width = w

          this.screen.isSM = w >= breakpoints.sm.min && w <= breakpoints.sm.max
          this.screen.isMD = w >= breakpoints.md.min && w <= breakpoints.md.max
          this.screen.upMD = w >= breakpoints.md.min
          this.screen.isLG = w >= breakpoints.lg.min && w <= breakpoints.lg.max
          this.screen.upLG = w >= breakpoints.lg.min
          this.screen.downLG = w < breakpoints.lg.min
          this.screen.isXL = w >= breakpoints.xl.min
        },
        getWindowHeight(event) {
          this.screen.height = document.documentElement.clientHeight
        }
      },
      beforeDestroy() {
        window.removeEventListener('resize', this.getWindowWidth)
        window.removeEventListener('resize', this.getWindowHeight)
      }
    })
  }
}
