<template lang='haml'>
  .attachment-segment.d-flex.mb-3.mt-n2(v-if="action != 'show'")
    .btn.btn-outline-dark(@click="addFile()" :title="$t('comm.transmissions.form.attach_file')")
      %i.pi.pi.pi-paperclip.py-1
    %template(v-for="file in files")
      .file-chosen.bg-light.border.rounded.d-inline-flex.align-items-center.py-2.px-3.ms-2(v-if="file.name")
        %span.file-name {{ file.name }}
        %i.pi.pi-times.ms-2.fs-6(@click="removeFile(file)")
      %input.d-none(type="file" name="form[attachments][]" :key="file.id" :id="`file-${file.id}`"
             @change="file.name = getFileName($event)" )
</template>

<script>
export default {
  props: ['segment', 'action', 'value'],
  data() {
    return {
      lastId: 0,
      files: []
    }
  },
  methods: {
    addFile() {
      const newFile = { id: this.lastId, name: '' }
      this.files.push(newFile)
      this.lastId++
      this.$nextTick(function() {
        // open file select windows
        this.$el.querySelector(`#file-${newFile.id}`).click()
      })
    },
    removeFile(file) {
      this.files.splice(this.files.indexOf(file), 1)
    },
    getFileName(event) {
      return event.target.value.split(/(\\|\/)/g).pop()
    }
  }
}
</script>

<style lang='scss' scoped>
  .file-chosen .pi-times:hover {
    cursor: pointer;
  }
</style>
