<template lang='haml'>
  %span.confirm-button.text-start
    .btn(@click.prevent.stop.target="displayDialog = true" :class="btnClass")
      %slot

    %Dialog(header="Confirmation" :visible.sync="displayDialog" position="top" :modal="true"
            :containerStyle="{width: '400px'}")
      %p.mb-0(v-html="message")
      %template(#footer="")
        .btn.btn-default.me-2(@click.prevent.stop="displayDialog = false")
          {{ $t('comm.lookups.general.no') }}
        .btn(:class="'btn-' + type" @click.prevent.stop="onConfirmed()")
          {{ $t('comm.lookups.general.yes') }}

    %form.d-none(v-if="href" method="post" :action="href")
      %input(name="_method" :value="method" type="hidden")
      %input(v-if="csrfParam() && csrfToken()"
             :name="csrfParam()" :value="csrfToken()" type="hidden")
      %input(type="submit" ref="submitBtn")
</template>

<script>
import Dialog from 'primevue/dialog'

export default {
  props: {
    message: {
      type: String,
      default() {
        return this.$t('comm.messages.are_you_sure')
      }
    },
    type: { type: String, default: 'primary' },
    method: { type: String, default: 'get' },
    btnClass: { type: String },
    href: { type: String }
  },
  data() {
    return { displayDialog: false }
  },
  methods: {
    onConfirmed() {
      this.displayDialog = false
      this.$emit('confirmed')
      if (this.$refs.submitBtn) this.$refs.submitBtn.click()
    },
    csrfToken() {
      const meta = document.querySelector('meta[name=csrf-token]')
      return meta ? meta.content : null
    },
    csrfParam() {
      const meta = document.querySelector('meta[name=csrf-param]')
      return meta ? meta.content : null
    },
    close() {
      this.displayDialog = false // using a ref you can call this method on your confirm action see confirmUnsubscribe in engines/mvip/app/assets/new_javascripts/mvip/pages/mvip_publication_subscriptions/get_confirm_subscribe.js
    }
  },
  components: { Dialog }
}
</script>
