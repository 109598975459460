<template lang='haml'>
  / This component is only used to preview segments in the location_variable_helper
  %a(href="https://link.to.doctor.form" taregt="_blank") https://link.to.doctor.form
</template>

<script>
export default {
  props: ['segment', 'action']
}
</script>
