function permittedTo(action, context) {
  return gon.permissions[context] && gon.permissions[context].includes(action)
}

// make rails permissions available from javascript
// this plugin adds a $permittedTo method on any Vue instance
export const RailsPermissionsPlugin = {
  install(Vue, options) {
    Vue.prototype.$can = permittedTo
  }
}

// Directive %div(v-can:edit:messages) === %div(v-show="$can('edit', 'messages')")
export function railsPermissionsDirective(el, binding, vnode) {
  const args = binding.arg.split(':')
  if (args.length != 2) {
    console.error('v-can directive needs two arguments sperated by column. Exple -> v-can:edit:messages')
  }
  if (!permittedTo(args[0], args[1])) {
    // replace HTMLElement with comment node https://stackoverflow.com/questions/43003976/a-custom-directive-similar-to-v-if-in-vuejs
    const comment = document.createComment(' ')
    Object.defineProperty(comment, 'setAttribute', { value: () => undefined })
    vnode.elm = comment
    vnode.text = ' '
    vnode.isComment = true
    vnode.context = undefined
    vnode.tag = undefined
    vnode.data.directives = undefined

    if (vnode.componentInstance) {
      vnode.componentInstance.$el = comment
    }

    if (el.parentNode) {
      el.parentNode.replaceChild(comment, el)
    }
  }
}
