import DanasTable from './DanasTable'
import DanaReportTable from './DanaReportTable'

export default {
  components: { DanasTable, DanaReportTable },
  data: {
    danas: [],
    reports: []
  },
  mounted() {
    this.danas = gon.danas.map((dana) => {
      dana.transaction_date = new Date(dana.transaction_date)
      dana.receipt = null // initialize the value so prop gets reactive
      return dana
    })
    this.reports = gon.reports.map((msg) => {
      msg.sent_at = new Date(msg.sent_at)
      return msg
    })
  }
}
