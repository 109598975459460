import Vue from 'vue'

// Alternative : use this lib (I just found it) : https://github.com/kuroda/vue-data-scooper/blob/master/src/vue-data-scooper.js

// Vue is made to generate template itself
// In CALM pages we are loading a vue component over an existing template
// On input, when attaching a v-model binding, the data is initialized from the vue
// instance, instead of the HTML dom
// Exple
// <input v-model="my_input" value="My value"/>
// new Vue({
//   data: {
//     my_input: null
//   }
// })
// When vue initialize, Vue model takes priority and the input result being initialized with null
// To avoid this, we provide a v-init directive, and we initialize this v-init automatically
// before Vue get initialized
// <input v-model="my_input" v-init:my_input="My value"/>
// Note : when used in ruby haml, it does not work with CamelCase properties, cause every html attribute is downcased
Vue.directive('init', {
  bind(el, binding, vnode) {
    if (Object.keys(binding.modifiers).length > 0) {
      // nested property, which is inerpreted as modifier v-init:filters.location_id="5"
      vnode.context[binding.arg][Object.keys(binding.modifiers)[0]] = binding.value
    } else {
      vnode.context[binding.arg] = binding.value
    }
  }
})

export default function initVModels() {
  // See v-init directive
  // TODO mvip: This should not run when navigate back from turbolink
  for (const el of document.querySelectorAll('[v-model]')) {
    let value
    switch (el.getAttribute('type')) {
      case 'checkbox':
        value = el.checked; break
      case 'radio':
        const checkedRadio = document.querySelector(`[name="${el.getAttribute('name')}"]:checked`)
        value = checkedRadio ? `'${checkedRadio.value}'` : null
        break
      default:
        value = el.value ? el.value : el.getAttribute('value')
        if (value) value = `'${value.replace(/(?:\r\n|\r|\n)/g, '<br>').replace(/(')/g, '&apos;')}'` // line breaks and single quote breaks the html syntax, so replacing
        break
    }
    if (value && typeof value == 'string') value = value.replace(/\\*('?)$/g, '$1') // Refs #5660 remove trailing \ which breaks the HTML
    if (value !== undefined) el.setAttribute(`v-init:${el.getAttribute('v-model')}`, value)
  }
}
