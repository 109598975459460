<template lang='haml'>
  %span(:title="text.length > length ? text : null")
    {{ text.length > length ? text.slice(0, length) + '...' : text }}
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true
    },
    length: {
      type: Number,
      default: 100
    }
  }
}
</script>
