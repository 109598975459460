<template lang='haml'>
  .choice-segment
    %strong.text-success(v-if="action == 'show'")
      {{ displaySegmentChoice(segment.value) }}
    %template(v-else)
      .form-check(v-for="choice in segmentChoices()")
        %input.form-check-input(:type="segment.multiple ? 'checkbox' : 'radio'"
          :id="segment.ident + choice"
          :name="`form[segments][${segment.ident}][]`"
          :value="choice"
          :disabled="action == 'preview'"
          @input="$emit('input', choice)"
          :required="!!segment.required")
        %label.form-check-label(:for="segment.ident + choice")
          {{ displaySegmentChoice(choice) }}

</template>

<script>
export default {
  props: ['segment', 'action', 'value'],
  methods: {
    segmentChoices() {
      if (this.segment.class_name == 'ActionSegment') return this.segment.actions
      return this.segment.choices
    },
    displaySegmentChoice(option) {
      if (this.segment.translations) {
        const index = this.segmentChoices().indexOf(option)
        if (this.segment.translations[index]) return this.segment.translations[index]
      }

      if (this.segment.class_name == 'ActionSegment') return this.$t(`comm.lookups.action_radio_buttons.${option}`)
      if (option == 'yes') return this.$t('comm.lookups.general.yes')
      if (option == 'no') return this.$t('comm.lookups.general.no')

      // If no translation, make it human readable
      return this.capitalizeSplit(option)
    },
    capitalizeSplit(word) {
      word = word ? word.replace(/_/g, ' ') : ''
      return word.charAt(0).toUpperCase() + word.slice(1)
    }
  }
}

</script>

<style lang='scss' scoped>
  .choice-segment {
    margin-top: .5rem;
  }
</style>
