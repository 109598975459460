import Dialog from 'primevue/dialog'

export default {
  components: { DialogComponent: Dialog },
  data: {
    people: [],
    selectedPerson: {},
    displayDialog: false
  },
  methods: {
    choosePerson(person) {
      this.selectedPerson = person
      if (person.primary_email != gon.search_email) {
        this.displayDialog = true
      } else {
        this.redirectToUrl()
      }
    },
    redirectToUrl(changePrimaryEmail = false) {
      const url = this.$routes.mvip_send_subscribtion_invite_path({
        subject: this.selectedPerson.subject,
        location_id: gon.location_id,
        iframe: gon.iframe,
        new_primary_email: changePrimaryEmail ? gon.search_email : null
      })
      window.location.href = url
    }
  },
  mounted() {
    this.people = gon.people_to_choose
  }
}
