export default {
  data: {
    personLanguages: [],
    languages: [],
    proficiencies: []
  },
  mounted() {
    this.languages = gon.languages
    this.personLanguages = gon.person_languages
    this.proficiencies = gon.proficiencies.map((prof) => [this.$t(`lookups.application_form.language_proficiency.${prof}`), prof])
  }
}
