<template lang='haml'>
  .messages-list
    %MessageForm(ref="form" :messages="messages" @msg-created="open($event)")

    / Table
    %DataTable(v-bind="tableBindings" v-on="tableListeners"
               :value="messages" :rowClass="rowClass"
               responsiveLayout="scroll"
               :paginator="messages.length > 10" :rows="10"
               sortField="display_sent_at" :sortOrder="-1")
      / Subject
      %Column(field="subject" :header="$t('comm.transmissions.index.subject')")
        %template(#body="slotProps")
          .title
            .pi.pi-exclamation-circle.me-2(v-if="slotProps.data.haveFormToComplete")
            %span.d-inline-flex.align-items-center
              %b-icon.me-2(icon="reply-fill" v-if="['form_completed', 'applicant_sent', 'applicant_draft'].includes(slotProps.data.status)")
              %CroppedText(:text="getMessageTitle(slotProps.data)" :length="50")
            .pi.pi-paperclip.ms-2.fs-6(v-if="slotProps.data.attachments.length > 0")
          .subtitle(v-if="screen.isSM")
            {{ slotProps.data.display_sent_at.toLocaleDateString(locale, {day: 'numeric', month: 'short', year: '2-digit' })}}
      / Sent at
      %Column(field="display_sent_at" :header="$t('comm.transmissions.index.received_at')"
              :sortable="true" v-if="screen.upMD")
        %template(#body="slotProps")
          {{ slotProps.data.display_sent_at.toLocaleDateString(locale, {day: 'numeric', month: 'short', year: '2-digit' })}}
      / Context
      -# %Column(field="context" :header="$t('comm.transmissions.index.context')" v-if="displayContext && screen.upMD")
      -#   %template(#body="slotProps")
      -#     .badge.text-bg-light {{ slotProps.data.context_display }}

      / Content
      %template(#expansion="slotProps")
        .actions.border-bottom.pb-3.mb-3(v-if="Object.values(slotProps.data.actions || {}).some(a => a)")
          %button.btn.btn-outline-secondary(v-if="slotProps.data.actions.answer" @click="openReplyFormLink()")
            {{ $t('comm.transmissions.index.answer_form') }}
          %button.btn.btn-outline-primary(v-if="slotProps.data.actions.reply" @click="newReply(slotProps.data)")
            %b-icon.me-2(icon="reply-fill")
            {{ $t('comm.lookups.actions.reply') }}
        .attachments-container.mb-3(v-if="slotProps.data.attachments.length > 0")
          %a.btn.btn-sm.btn-outline-dark.me-2(v-for="attachment in slotProps.data.attachments" target="_blank"
                                              :href="$routes.mvip_download_attachment_path(attachment.id)")
            {{ attachment.name}}
        .msg-content(ref="msgContent")
          %SegmentsRenderer(:segments="slotProps.data.segments" action="show")
      / Empty
      %template(#empty)
        .text-center.p-4 {{ $t('comm.transmissions.index.no_messages') }}

      %Column(:expander="true" bodyClass="expander")
</template>

<script>
import { BIconReplyFill } from 'bootstrap-vue'
import DatatableMixin from 'mvip/helpers/datatable-expandable-mixin'
import SegmentsRenderer from 'components/letters/SegmentsRenderer'
import MessageForm from './MessageForm.vue'

export default {
  props: ['messages'],
  mixins: [DatatableMixin],
  components: { BIconReplyFill, SegmentsRenderer, MessageForm },
  data() {
    return { expandedRows: [] }
  },
  mounted() {
    // next tick in order to wait for calm select to be initialized
    this.$nextTick(function() {
      if (gon.display_msg_id) {
        const msgToEmphasis = this.messages.find((msg) => msg.id == gon.display_msg_id)
        if (msgToEmphasis) this.expandedRows = [msgToEmphasis]
      }
    })
  },
  methods: {
    rowClass(msg) {
      const classes = []
      if (msg.haveFormToComplete) classes.push('form-not-completed')
      if (this.expandedRows.includes(msg)) classes.push('expanded')
      return classes.join(' ')
    },
    open(msg) {
      this.expandedRows = [msg]
    },
    openReplyFormLink() {
      if (this.$refs.msgContent) this.$refs.msgContent.querySelector('a[href*="edit?token"]').click()
    },
    newReply(msg) {
      this.$refs.form.open({
        course_application_id: msg.app_id,
        subject: `Re: ${msg.subject}`
      })
    },
    getMessageTitle(msg) {
      if (msg.status == 'note_saved' || msg.delivery_method == 'sms') return msg.template_name // sms have no subject
      if (msg.status.includes('doctor')) return this.$t('lookups.message_status.form_completed_doctor')
      if (msg.status == 'form_completed') return this.$t('comm.transmissions.index.form_reply')
      return msg.subject || this.$t('lookups.transmission_template.custom_message.name')
    }
  }
}
</script>

<style lang='scss' scoped>
  ::v-deep .form-not-completed .title {
    font-weight: bold;
    color: var(--bs-secondary) !important;
  }
  ::v-deep .p-datatable-thead {
    display: none;
  }
</style>
