<template lang='haml'>
  .columns-picker.gap-2.float-end(v-show="activated")

    / Layouts List
    %LayoutPicker(@click-destroy="doDestroy" :layouts="layouts" v-if="layouts.length > 0"
                  @layout-selected="loadLayout" :selectedLayout="selectedLayout")
    / Save New Layout Button
    %CalmButton.btn-success(v-if="!selectedLayout.id || selectedLayoutChanged"
      @click="openSavePanel" icon="pi pi-save" |
      v-tooltip.top="$t('datatable.common.save_layout')")

    / Columns picker
    %CalmButton.btn.btn-dark(@click="toggleColumnsPanel" icon="fas fa-cog" |
                              v-tooltip.top="$t('datatable.common.columns')")
    / Save Dialog
    %OverlayPanel(ref="savePanel")
      .d-flex.align-items-center.p-3
        %template(v-if="selectedLayout.editable")
          / Update existing
          %CalmButton.btn-primary(
            icon="fas fa-sync-alt" @click="doUpdate" :loading="loadingUpdate"
            :label="$t('datatable.common.update', { name: selectedLayout.name })")
          %span.mx-3 {{ $t('comm.lookups.general.or') }}
        / Create new layout
        .input-group.flex-grow-1
          %input.form-control(type="text" v-model="layoutName" @keyup.enter="doCreate"
                              ref="layoutNameInput"
                              :placeholder="$t('datatable.common.layout_name')" )
          .input-group-btn
            %CalmButton.btn-success(@click="doCreate" :loading="loadingCreate"
                                    :disabled="!layoutName"
                                    :label="$t('datatable.common.create_layout')")

    %OverlayPanel(ref="columnsPanel" :showCloseIcon="false" :dismissable="true")
      / Columns List
      .columns-container
        .columns-group(v-for="(columns, group) in groupedTogglableColumns" |
                      :style="group == 'undefined' ? 'order: 100' : ''")
          .group-title(v-if="Object.keys(groupedTogglableColumns).length > 0")
            {{ group == 'undefined' ? $t('datatable.common.other_group') : group }}
          .checkbox(v-for="column in columns")
            %input(type="checkbox" :id="column.field" v-model="column.selected"
                   @change="onColumnChanged()")
            %label(:for="column.field" v-tooltip.right="column.headerTitle || {disabled: true}")
              {{ column.header }}
              %span.ms-1.asterix(v-show="column.headerTitle") (?)

       / Header
      .overlay-footer.d-flex.justify-content-end
        / Uncheck All
        .btn.border-0.me-2(@click="uncheckAllColumns")
          {{ $t('datatable.common.uncheck_columns') }}
        / Close Button
        %CalmButton.btn-dark(@click="$refs.columnsPanel.hide($event)" :loading="loadingColumns"
                             :label="$t('datatable.common.done')")
</template>

<script>
import OverlayPanel from 'primevue/overlaypanel'
import Dialog from 'primevue/dialog'
import CrudMixin from 'mvip/helpers/crud-mixin.js'
import Divider from 'primevue/divider'
import CalmButton from 'components/common/CalmButton'
import LayoutPicker from './LayoutPicker.vue'

export default {
  mixins: [CrudMixin],
  components: { OverlayPanel, Dialog, LayoutPicker, Divider, CalmButton },
  props: {
    activated: {
      type: Boolean,
      default: true
    },
    columns: {
      type: Array,
      default() { return [] }
    },
    fieldsToHide: {
      type: Array,
      default() { return [] }
    },
    columnsToDisplay: {
      type: Array,
      default() { return [] }
    },
    tableKey: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      selectedLayout: {},
      selectedLayoutChanged: false,
      layouts: [],
      layoutName: '',
      clickEvent: {},
      loadingColumns: false
    }
  },
  computed: {
    togglableColumns() {
      return this.columns.filter((col) => col.togglable && !col.hidden && !this.fieldsToHide.includes(col.field))
    },
    groupedTogglableColumns() {
      return this.togglableColumns.reduce((result, col) => {
        (result[col.group] = result[col.group] || []).push(col)
        return result
      }, {})
    },
    storageKey() {
      return `${this.tableKey}_layout`
    }
  },
  created() {
    this.layouts = gon.datatable.layouts
    let layoutToLoad = JSON.parse(window.localStorage.getItem(this.storageKey))
    if (layoutToLoad && layoutToLoad.id) layoutToLoad = { ...this.originalLayout(layoutToLoad.id), ...layoutToLoad }
    layoutToLoad ||= this.layouts[0]
    // display all columns by default
    layoutToLoad ||= { columns: this.togglableColumns.map((col) => col.field) }
    this.loadLayout(layoutToLoad)
    this.detectLayoutChange()
    this.updateColumnsToDisplay()
    // init crud mixin
    this.crud.layouts = {
      route: 'datatable_layout',
      params: {},
      label: this.$t('datatable.common.layout')
    }
  },
  methods: {
    toggleColumnsPanel(event) {
      this.clickEvent = event
      this.$refs.columnsPanel.toggle(event)
      this.$forceUpdate() // fix some reactivity issue for column.selected checkbox
    },
    uncheckAllColumns() {
      this.columns.forEach((col) => { col.selected = false })
      this.$forceUpdate() // fix some reactivity issue for column.selected checkbox
      this.onColumnChanged()
    },
    selectedFields() {
      return this.columns.filter((col) => col.selected).map((col) => col.field)
    },
    onColumnChanged() {
      this.loadingColumns = true
      // Wait for loading button to be drawn
      setTimeout(() => {
        this.updateColumnsToDisplay()
        window.localStorage.setItem(this.storageKey, JSON.stringify({
          id: this.selectedLayout.id,
          columns: this.selectedFields()
        }))
        this.detectLayoutChange()
      }, 0)
    },
    updateColumnsToDisplay() {
      // In order to preserve columns orders, we need to force redraw by reseting the columns to display
      const cols = this.columns.filter((col) => {
        if (this.activated && this.togglableColumns.includes(col)) return col.selected
        return !this.fieldsToHide.includes(col.field)
      })
      this.$emit('update:columns-to-display', [])
      this.$nextTick(() => {
        this.$emit('update:columns-to-display', cols)
        this.loadingColumns = false
      })
    },
    originalLayout(id = this.selectedLayout.id) {
      if (!id) return {}
      return this.layouts.find((layout) => layout && layout.id == id) || {}
    },
    loadLayout(layout) {
      this.selectedLayout = layout
      this.selectedLayoutChanged = false
      this.columns.forEach((col) => {
        col.selected = this.selectedLayout.columns.includes(col.field)
      })
      this.onColumnChanged()
    },
    detectLayoutChange() {
      if (!this.selectedLayout.id) return
      this.selectedLayoutChanged = !this.arrayEquals(this.originalLayout().columns, this.selectedFields())
    },
    arrayEquals(a, b) {
      b = b.sort()
      return Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.sort().every((val, index) => val === b[index])
    },
    openSavePanel(event) {
      this.layoutName = ''
      this.$refs.savePanel.show(event)
      this.$nextTick(function() {
        this.$refs.layoutNameInput.focus()
      })
    },
    doCreate() {
      this.create('layouts', {
        table_key: this.tableKey,
        name: this.layoutName,
        editable: true,
        columns: this.selectedFields()
      }, (result) => {
        this.loadLayout(result.data)
        this.$refs.savePanel.hide()
      })
    },
    doUpdate() {
      this.update(
        'layouts',
        { ...this.selectedLayout, ...{ columns: this.selectedFields() } },
        (result) => {
          this.loadLayout(result.data)
          this.$refs.savePanel.hide()
        }
      )
    },
    doDestroy(layout) {
      this.destroy('layouts', layout, (result) => {
        if (this.selectedLayout.id == layout.id) {
          this.selectedLayout = {}
          this.selectedLayoutChanged = false
        }
      })
    }
  },
  watch: {
    fieldsToHide(newVal, oldVal) {
      if (!this.arrayEquals(newVal, oldVal)) this.updateColumnsToDisplay()
    }
  }
}
</script>

<style lang='scss' scoped>
  .columns-picker {
    display: flex;
  }
  ::v-deep .p-overlaypanel {
    top: initial !important;
    left: initial !important;
    right: 0 !important;
    margin-top: 3rem;
    transform-origin: top !important;

    a, .btn { margin-bottom: 0 } // cancel the rule made on datatable-header

    &:before, &:after {
      content: none;
    }
    .p-overlaypanel-content {
      padding: 0;
    }
    .overlay-footer {
      padding: .8rem 1rem;
      background-color: #f6f6f6;
      border-radius: 0 0 var(--border-radius) var(--border-radius);
    }
    .columns-container {
      display: flex;
      column-gap: 2rem;
      padding: 1.25rem;
      padding-bottom: .7rem;
      .group-title {
        font-weight: bold;
      }
    }
  }

  .checkbox {
    label {
      font-weight: normal;
    }
    input:checked + label {
      color: var(--bs-primary);
    }
    .asterix {
      font-size: .8rem;
      opacity: .8;
    }
  }
</style>
