<template lang='haml'>
  .active-application
    %MessageForm(ref="form" @msg-created="onNewMessage($event)" :messages="messages")

    .card.mb-4.full-width-sm
      .card-header.d-flex.align-items-center(:class="active ? 'text-bg-primary' : 'inactive'")
        .title.me-auto.d-flex.align-items-center
          %span.course-summary.ff-heading.fw-semibold.fs-11-rem
            %span {{ eventDisplay }}
            %span.participation(v-if="app.participation != 'new_student'") {{ participationDisplay }}
          %AppStateDisplay(:app="app")
        .d-none.d-md-block
          / Contact Us
          %a.btn(@click="newMessage" :class="active ? 'btn-outline-light' : 'btn-outline-dark'")
            {{ $t('comm.transmissions.index.contact') }}
          / Cancel App
          %a.btn.btn-secondary.ms-3(v-if="cancellable"
                                    :href="$routes.mvip_mvip_cancel_application_path({ id: app.id })")
            {{ $t('comm.lookups.action_radio_buttons.cancel') }}
      .card-body
        %p.card-text.date(v-show="app.event_type != 'LongTermService'")
          %i.pi.pi-calendar.me-2
          %span {{ app.start_date.toLocaleDateString(locale, {day: 'numeric', month: 'short'}) }}
          %span= " - "
          %span {{ app.end_date.toLocaleDateString(locale, {day: 'numeric', month: 'short', year: 'numeric' }) }}
        %p.card-text
          %i.pi.pi-map-marker.me-2
          {{ app.venue }}
        .actions.d-flex
          / See Messages
          .btn.me-auto.align-items-baseline(@click="loadMessages" :class="btnMessageClass")
            {{ $t('comm.course_applications.actions.see_messages') }}
            %span.ms-1.fs-08-em
              %i.pi.pi-minus(v-if="showMessages")
              %i.pi.pi-plus(v-else)
          / Contact Us
          %a.btn.btn-sm.ms-2.d-md-none(@click="newMessage" :class="active ? 'btn-outline-secondary' : 'btn-outline-dark'")
            {{ $t('comm.transmissions.index.contact') }}
          / Cancel App
          %a.btn.btn-sm.btn-secondary.ms-2.d-md-none(v-if="cancellable"
                                          :href="$routes.mvip_mvip_cancel_application_path({ id: app.id })")
            {{ $t('comm.lookups.action_radio_buttons.cancel') }}
      .messages-container.card-body.border-top.p-0(v-if="showMessages")
        %CalmSpinner.my-3(v-show="!messagesLoaded")
        %MessagesList(v-show="messagesLoaded" :messages="messages" ref="msgList")
</template>

<script>
import { snakeCase } from 'helpers/string_helper'
import { get, handleAjaxError } from 'helpers/core/ajax-utils'
import messagesTransformMixin from 'mvip/pages/mvip_transmissions/messages-transform-mixin'

import MessageForm from 'mvip/pages/mvip_transmissions/MessageForm.vue'
import MessagesList from 'mvip/pages/mvip_transmissions/MessagesList.vue'
import AppStateDisplay from './AppStateDisplay.vue'

export default {
  props: ['app'],
  mixins: [messagesTransformMixin],
  components: { AppStateDisplay, MessageForm, MessagesList },
  data() {
    return {
      showMessages: false,
      messagesLoaded: false,
      messages: []
    }
  },
  computed: {
    participationDisplay() {
      const key = this.app.participation.includes('server') ? 'service' : 'sitting'
      return this.$t(`comm.course_applications.attrs.participation_values.${key}`)
    },
    eventDisplay() {
      return this.$t(`activerecord.models.${snakeCase(this.app.event_type)}.one`)
    },
    cancellable() {
      const cancelledStatus = ['Cancelled', 'Discontinued', 'NewNoVacancy', 'NoVacancy', 'Refused']
      return this.app.start_date > new Date() && !cancelledStatus.includes(this.app.conf_state)
    },
    active() {
      return !['Cancelled', 'Discontinued', 'NewNoVacancy', 'NoVacancy', 'Refused'].includes(this.app.conf_state)
    },
    btnMessageClass() {
      let result = this.screen.isSM ? 'btn-sm ' : ''
      if (!this.active) result += 'btn-outline-dark'
      else result += this.screen.isSM ? 'btn-outline-primary' : 'btn-outline-secondary'
      return result
    }
  },
  methods: {
    newMessage() {
      this.$refs.form.open({ course_application_id: this.app.id }, { displaySave: false })
    },
    loadMessages() {
      this.showMessages = !this.showMessages
      if (!this.messagesLoaded) {
        get(this.$routes.mvip_student_messages_path({ course_application_id: this.app.id }))
          .then((result) => {
            this.messagesLoaded = true
            this.messages = this.transformMessages(result.data)
          }).catch((error) => {
            handleAjaxError(error, this)
          })
      }
    },
    onNewMessage(msg) {
      if (this.$refs.msgList) this.$refs.msgList.open(msg)
    }
  }
}
</script>

<style lang='scss'>
  .active-application {
    .card-header.inactive {
      background-color: var(--bs-blue-200) !important;
    }
    .participation {
      &:before {
        content: " - ";
      }
    }
    .messages-container {
      .p-datatable {
        margin-bottom: 0;
        .p-datatable-wrapper {
          border-top-right-radius: 0 !important;
          border-top-left-radius: 0 !important;
          .p-datatable-thead {
            display: none;
          }
        }
      }
    }
    @media (max-width: 768px) {
      .title {
        width: 100%;
        .course-summary {
          margin-right: auto;
        }
      }
      .date {
        margin-bottom: .5rem;
      }
      .p-datatable-wrapper {
        margin-right: 0;
        margin-left: 0;
      }
    }
  }
</style>
