<template lang='haml'>
  %span.app-state(v-show="confStateDisplay")
    {{ confStateDisplay }}
</template>

<script>
export default {
  props: ['app'],
  data() {
    return {
      inProgressState: ['NewApplication', 'NewPendingForConfirmation', 'PendingForConfirmation', 'PendingRandomSelect',
        'NewPendingForWaitlist', 'PendingForWaitlist'],
      waitListStates: ['ConfirmableWaitlist', 'WaitListReqReconfirm', 'WaitListReconfirmed'],
      reconfStates: ['WaitListReqReconfirm', 'RequestedReconfirm'],
      acceptedStates: ['Confirmed', 'Reconfirmed']
    }
  },
  computed: {
    state() {
      return this.app.conf_state
    },
    confStateDisplay() {
      if (!this.state) return ''
      if (this.reconfStates.includes(this.state)) {
        return this.$t('comm.course_applications.attrs.conf_state_simple.reconfirm')
      } if (this.acceptedStates.includes(this.state)) {
        return this.$t('comm.course_applications.attrs.conf_state_simple.accepted')
      } if (this.waitListStates.includes(this.state)) {
        return this.$t('comm.course_applications.attrs.conf_state_simple.waitlist')
      } if (this.inProgressState.includes(this.state)) {
        return this.$t('comm.course_applications.attrs.conf_state_simple.in_progress')
      }
      return this.$t(`lookups.course_app_confirmation_states.shorter_display_name.${this.state}`)
    }
  }
}
</script>

<style lang='scss' scoped>
  .app-state {
    background-color: var(--bs-gold-200);
    color: var(--bs-black);
    font-style: italic;
    padding: 0.2rem 1rem 0.2rem 0.8rem;
    border-radius: 1rem;
    font-size: .85rem;
    margin-left: 1rem;
  }
  .card-header.inactive .app-state {
    background-color: var(--bs-blue-150);
  }
</style>
