import DataTable from 'primevue/datatable'
import Column from 'primevue/column'

export default {
  components: { DataTable, Column },
  props: ['data'],
  data() {
    return { expandedRows: [] }
  },
  computed: {
    // DataTable html attributes
    tableBindings() {
      return {
        value: this.data,
        paginator: this.data > this.perPage,
        rows: this.perPage,
        dataKey: 'id',
        class: 'hoverable',
        expandedRows: this.expandedRows,
        rowClass: (item) => (this.expandedRows.includes(item) ? 'expanded' : '')
      }
    },
    tableListeners() {
      return {
        'row-expand': (event) => {
          this.onToggleExpand(event.data)
        },
        'row-click': (event) => {
          const row = event.data
          if (this.expandedRows.includes(row)) this.expandedRows = []
          else this.expandedRows = [row]
          this.onToggleExpand(row)
        },
        'update:expandedRows': (value) => {
          this.expandedRows = value
        }
      }
    }
  },
  methods: {
    onToggleExpand(row) {
      // Implement in Component if needed
    }
  }
}
