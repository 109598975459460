<template lang='haml'>
  .spinner-container
    .spinner
      %div
      %div
      %div
      %div
</template>

<script>
export default {

}
</script>

<style lang='scss' scoped>
  .spinner-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3rem;
  }
</style>