// This Mixin allow to manage CRUD operations
// If those CRUD operation need to trigger ajax request then you need to configure the route

// HOW TO USE
// export default {
//   data: {
//     applications: [],
//     danas: [],
//     person_id: 5
//   },
//   mounted() {
//     // Configuring dana route will trigger ajax request on every dana operation
//     this.crud.danas = {
//       route: 'mvip_dana', // singular route name -> mvip_dana_path, mvip_danas_path
//       // be careful, the route will not work if plural is not with s, for example person_path, people_path
//       params: {person_id: this.person_id, format: 'json'}, // route params if needed
//       label: "Dana" // model name, used in Toast message
//     }
//     this.crud.applications = {}
//   },
//   anywhere() {
//     this.create('danas', {/* dana object*/})
//     this.update('danas', {/* dana object*/})
//     this.destroy('danas', {/* dana object*/})

//     // Following will not trigger ajax, but only update the applications array (cause no route is configured)
//     this.create('applications', {/* app object*/})
//     this.update('applications', {/* app object*/})
//     this.destroy('applications', {/* app object*/})
//   }
// }
import { post, patch, destroy, handleAjaxError } from 'helpers/core/ajax-utils'

export default {
  data() {
    return {
      crud: {},
      loadingCreate: false,
      loadingUpdate: false,
      loadingDestroy: false
    }
  },
  methods: {
    // CREATE
    create(attr, item, callback = null) {
      if (this.crud[attr].route) {
        this.loadingCreate = true
        const url = this.$routes[`${this.crud[attr].route}s_path`](this.crud[attr].params)
        post(url, item)
          .then((result) => {
            item.id = result.data.id
            this.createWithoutSaving(attr, item)
            this.toast(attr, 'save_success')
            this.loadingCreate = false
            if (callback) callback(result)
          }).catch((error) => {
            this.loadingCreate = false
            handleAjaxError(error, this)
          })
      } else {
        if (!this.crud[attr].createdCount) this.crud[attr].createdCount = 1
        item.id = `new${this.crud[attr].createdCount}` // create incremental fake id
        this.crud[attr].createdCount += 1
        this.createWithoutSaving(attr, item)
      }
    },
    createWithoutSaving(attr, item) {
      this[attr].push(item)
    },
    // UPDATE
    update(attr, item, callback = null) {
      if (this.crud[attr].route) {
        this.loadingUpdate = true
        patch(this.updateAndDestroyUrl(attr, item), item)
          .then((result) => {
            this.updateWithoutSaving(attr, item)
            this.toast(attr, 'update_success')
            this.loadingUpdate = false
            if (callback) callback(result)
          })
          .catch((error) => {
            this.loadingUpdate = false
            handleAjaxError(error, this)
          })
      } else {
        this.updateWithoutSaving(attr, item)
      }
    },
    updateWithoutSaving(attr, item) {
      const index = this[attr].findIndex((a) => a.id == item.id)
      this[attr].splice(index, 1, item)
    },
    // DESTROY
    destroy(attr, item, callback = null) {
      if (this.crud[attr].route) {
        this.loadingDestroy = true
        destroy(this.updateAndDestroyUrl(attr, item), { params: { id: item.id } })
          .then((result) => {
            this.destroyWithoutSaving(attr, item)
            this.toast(attr, 'delete_success', 'info')
            this.loadingDestroy = false
            if (callback) callback(result)
          })
          .catch((error) => {
            this.loadingDestroy = false
            handleAjaxError(error, this)
          })
      } else {
        this.destroyWithoutSaving(attr, item)
      }
    },
    destroyWithoutSaving(attr, item) {
      this[attr].splice(this[attr].indexOf(item), 1)
    },

    // PRIVATE

    toast(attr, key, severity = 'success') {
      if (!this.crud[attr].label) return
      this.$toast.add({
        severity,
        summary: this.$t(`comm.lookups.crud_messages.${key}`, { model: this.crud[attr].label }),
        life: 3000
      })
    },
    // Don't know why, in Mvip, resource :danas provide only danas_path helper which works for both POST, PUT, DELETE
    // but in registration, resource :danas provide both danas_path and dana_path, danas_path can be used for POST
    // but for PUT and DELETE we should use dana_path
    updateAndDestroyUrl(attr, item) {
      const routeKey = `${this.crud[attr].route}_path`
      const routeKeyPlural = `${this.crud[attr].route}s_path`
      const routeMethod = this.$routes[routeKey] || this.$routes[routeKeyPlural]
      return routeMethod({ ...this.crud[attr].params, ...{ id: item.id } })
    }
  }
}
