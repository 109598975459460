import { axios, handleAjaxError } from 'helpers/core/ajax-utils'
import ConfirmButton from '../../components/common/ConfirmButton.vue'

export default {
  components: { confirmbutton: ConfirmButton },
  data: {
    confirmPath: undefined,
    unsubscribePath: undefined,
    successResult: undefined,
    missingSubscription: false,
    buttonsDisabled: false
  },
  methods: {
    confirmSubscription() {
      this.buttonsDisabled = true
      axios.patch(this.confirmPath).then((result) => {
        if (!this.checkSubsMissing(result)) {
          this.successResult = result.data.message
        }
      }).catch((error) => {
        this.successResult = undefined
        handleAjaxError(error, this)
      }).then((_result) => {
        this.buttonsDisabled = false
      })
    },
    unsubscribe() {
      this.buttonsDisabled = true
      axios.patch(this.unsubscribePath).then((result) => {
        if (!this.checkSubsMissing(result)) {
          this.successResult = result.data.message
          this.$refs.confirmUnsubscribe.close()
        }
      }).catch((error) => {
        this.successResult = undefined
        handleAjaxError(error, this)
      }).then((_result) => {
        this.buttonsDisabled = false
      })
    },
    checkSubsMissing(result) {
      if (result.data.message == 'subscription_missing') {
        this.successResult = undefined
        this.missingSubscription = true
        this.buttonsDisabled = false
        return true
      }
      return false
    }
  },
  mounted() {
    this.confirmPath = gon.confirm_path
    this.unsubscribePath = gon.unsubscribe_path
    this.missingSubscription = gon.missing_subscription
  }
}
