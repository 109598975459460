import MessagesIndex from './MessagesIndex.vue'
import messagesTransformMixin from './messages-transform-mixin'

export default {
  mixins: [messagesTransformMixin],
  components: { MessagesIndex },
  data: {
    messages: [],
    course_applications: []
  },
  mounted() {
    this.course_applications = gon.messages_course_applications.map((app) => {
      app.event_start_date = new Date(app.event_start_date)
      app.event_end_date = new Date(app.event_end_date)
      app.display = `${app.venue} - ${app.event_start_date.toLocaleDateString(this.locale, { day: 'numeric', month: 'short', year: '2-digit' })}`
      return app
    })
    this.messages = this.transformMessages(gon.messages)
  }
}
