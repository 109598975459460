<template lang='haml'>
  %Dialog(:header="$t('comm.transmissions.form.new_message_title')" :visible.sync="displayDialog")
    .message-form
      %input.form-control.mb-3.mt-1(type="text" v-model="message.subject" :placeholder="$t('comm.transmissions.index.subject')", maxlength="50")
      %textarea.form-control.mb-3.mt-1(rows="10" v-model="message.content" :placeholder="$t('comm.transmissions.index.message')")
    %template(#footer="")
      .btn.btn-default.me-2(@click="displayDialog = false") {{ $t('lookups.actions.cancel')}}
      .btn.btn-outline-primary.me-2(v-show="displaySave" @click="submit(false)") {{ $t('comm.lookups.actions.save') }}
      .btn.btn-primary(@click="submit(true)") {{ $t('comm.transmissions.form.send') }}
</template>

<script>
import { axios, handleAjaxError } from 'helpers/core/ajax-utils'
import Dialog from 'primevue/dialog'
import messagesTransformMixin from './messages-transform-mixin'

export default {
  props: ['messages'],
  mixins: [messagesTransformMixin],
  components: { Dialog },
  data() {
    return {
      message: {},
      displayDialog: false,
      displaySave: false
    }
  },
  methods: {
    open(msg = {}, opts = { displaySave: false }) {
      this.message = msg
      this.displayDialog = true
      this.displaySave = opts.displaySave
    },
    submit(deliver) {
      axios.post(this.$routes.mvip_student_create_message_path(), { message: this.message, deliver }).then((result) => {
        let msg = this.transformMessage(result.data)
        const allMessages = this.$root.messages || this.messages || []
        msg = this.calculateActions(msg, allMessages.concat([msg]))
        this.$toast.add({
          severity: 'success',
          summary: this.$t(`comm.lookups.crud_messages.sent_success`, { model: this.$t('comm.transmissions.index.message') }),
          life: 3000
        })
        allMessages.push(msg)
        this.$emit('msg-created', msg)
        this.displayDialog = false
      }).catch((error) => handleAjaxError(error, this))
    }
  }
}
</script>

<style lang='scss'>
  .message-form {
    // simplify toolbar
    .ql-background, .ql-font, .ql-code-block {
      display: none !important;
    }
  }
</style>
