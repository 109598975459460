import Vue from 'vue'

// Global helpers loaded for every pages
import 'helpers/core/turbolinks-init'
import initIframeListener from 'helpers/core/iframe-communication'

// Global PrimeVue components
import PrimeVue from 'primevue/config'
import ToastService from 'primevue/toastservice'
import Toast from 'primevue/toast'
import Tooltip from 'primevue/tooltip'

// Base CALM components
import registerComponents from 'helpers/core/register-base-components'

import CalmPassword from 'components/common/CalmPassword'
import CalmSelect from 'components/common/CalmSelect'

// Bootstrap Icons. To keep the pack as small as possible,
// you need to import each icon individually, see header.js for an example
import { BIcon, BIconPersonCircle, BIconTranslate, BIconList } from 'bootstrap-vue'

// import pages script which will be automatically lodaded base on the page name
// Registration App and Mvip do not work the same, so we change the config here, used by module auto loader
import { importAll, autoLoadModuleFromRenderedViewName } from 'helpers/core/module-auto-loader'
import initVModels from 'helpers/core/v-init-models'

import Rails from 'rails-ujs'

// Primevue styles
import 'primevue/resources/themes/mdc-light-indigo/theme.css' // You can change the theme here
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'

// Stylesheets
import 'mvip/myvipassana.scss'

// TODO mvip we are loading all the routes here, which is 160kb. We should do something like
// with translations : detect which route are being used in javascript and provide only those ones
window.Routes = require('routes.js.erb')

Vue.use(PrimeVue)
Vue.use(ToastService)
Vue.component('toast', Toast)
Vue.directive('tooltip', Tooltip)
registerComponents(require.context('mvip/components/common', true, /.*\.vue$/))
Vue.component('calm-password', CalmPassword)
Vue.component('calm-select', CalmSelect)
Vue.component('BIcon', BIcon)
Vue.component('BIconPersonCircle', BIconPersonCircle)
Vue.component('BIconTranslate', BIconTranslate)
Vue.component('BIconList', BIconList)
importAll(require.context('mvip/pages', true, /.*\.(ts|js|vue)$/))

let loadPageTimer = null

document.addEventListener('turbolinks:load', () => {
  clearTimeout(loadPageTimer)
  document.body.classList.remove('loading', 'long-loading')

  initVModels()
  initIframeListener()
  autoLoadModuleFromRenderedViewName('.vue-container', true)
  const autoClickBtn = document.querySelector('.btn.auto-click')
  if (autoClickBtn) autoClickBtn.click()

  // wait for vue to be mounted before drawing the page, otherwise he have some UI glitches
  setTimeout(() => {
    document.querySelector('.vue-container').classList.add('mounted')
  }, 0)
  const autofocus = document.querySelector('[autofocus]')
  if (autofocus) autofocus.focus()
})

// Display loading throbber while page loads Refs #5493
document.addEventListener('turbolinks:before-visit', () => {
  document.body.classList.add('loading')
  // Display a loading if request takes more than 500ms to complete
  loadPageTimer = setTimeout(() => document.body.classList.add('long-loading'), 500)
})

Rails.start()
