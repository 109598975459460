<template lang='haml'>
  .person-language.row
    .input-group.mb-2
      .input-group-text.pub-preference(:title="$t('comm.people.form.publication_preference_title')")
        %span.diese #
        {{ index + 1 }}
      %calm-select(v-if="personLanguage.isNew" :options="assignableLanguages" v-model="personLanguage.language_id")
      %calm-select(v-else :options="[language]" :disabled="true" class="disabled" v-model="personLanguage.language_id")
      -# .input-group-text(style="width: 50%" v-else) {{ language[0] }}
      %calm-select(:options="$root.proficiencies" v-model="personLanguage.proficiency")
      .btn-delete.input-group-text.btn.pi.pi-times.bg-transparent(@click="$emit('delete', personLanguage)")
</template>

<script>
export default {
  props: ['personLanguage', 'index', 'assignableLanguages'],
  computed: {
    language() {
      return this.$root.languages.find((obj) => obj[1] == this.personLanguage.language_id) || {}
    }
  }
}
</script>

<style lang='scss'>
.person-language {
  .calm-select-container.disabled {
    background-color: var(--bs-blue-150);
  }
  .multiselect--disabled {
    background-color: transparent;
    opacity: 1;
  }
  .pub-preference {
    background-color: transparent;
    &:hover {
      cursor: move;
      background-color: var(--bs-blue-150);
    }

    .diese {
      font-size: .85em;
      line-height: 1.5rem;
      opacity: .8;
      margin-right: 3px;
    }
  }
  .btn-delete {
    padding-right: 0.7rem !important;
  }
}
</style>
