import Vue from 'vue'
import VueI18n from 'vue-i18n'
import RailsRoutesPlugin from 'helpers/core/rails-routes-plugin'
import LocalizePlugin from 'helpers/core/localize-plugin'
import { RailsPermissionsPlugin, railsPermissionsDirective } from 'helpers/core/rails-permissions'
import MediaQueryMixin from 'helpers/core/media-query-mixin'

// register globally
Vue.use(VueI18n)
Vue.use(RailsPermissionsPlugin)
Vue.directive('can', railsPermissionsDirective)
Vue.use(MediaQueryMixin)
Vue.use(LocalizePlugin)
Vue.mixin({
  data() {
    return { locale: undefined }
  },
  mounted() {
    this.locale = gon.locale
  },
  methods: {
    openLocalePicker() {
      this.$refs.localePicker.toggle()
    }
  },
  watch: {
    locale(newLocale, oldLocale) {
      if (!newLocale || !oldLocale) return
      const url = new URL(window.location.href)
      url.searchParams.set('save_interface_lang', 'true')
      window.location.href = url.toString().replace(`/${oldLocale}/`, `/${newLocale}/`)
    }
  }
})

// A Vue wrapper to add custom default options
export default class {
  constructor(options, selector = '#bigcontainer') {
    if (!options.el && selector) {
      options.el = selector
    }

    options.i18n = new VueI18n({
      locale: 'en', // we make vue think it's always en, but actually the translations given for 'en' will be in the user locale, see set_frontend_translations
      messages: { en: gon.i18n }
    })

    // Calling here so Route javascript file is loaded
    Vue.use(RailsRoutesPlugin)

    return new Vue(options)
  }
}
