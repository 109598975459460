<template lang='haml'>
  %Dialog(:header="title" :modal="true" :visible.sync="displayAppForm")
    %form.needs-validation(v-if="editingApp" novalidate)

      / Event Type
      .mb-3
        %calm-select(v-model="editingApp.event_type" :options="eventTypeList()"
                     :placeholder="$t('comm.course_applications.attrs.event_type')" :maxHeight="200")

      / Participation
      .mb-3(v-if="screen.isSM")
        %calm-select(:options="participationList()" v-model="editingApp.participation" label="label" track-by="value")
      .pt-1.mb-3.d-flex(v-else)
        .form-check.form-check-inline(v-for="participation in participationList()")
          %input.form-check-input(v-model="editingApp.participation" :value="participation.value"
                                  type="radio" :id="participation.value" required)
          %label.form-check-label(:for="participation.value") {{ participation.label }}

      / Count
      .mb-3
        .input-group
          .input-group-text {{ $t('comm.course_applications.attrs.batch_number_of_courses') }}
          %input.form-control(type="number" v-model="editingApp.batch_count")

      / Note
      .mb-3
        %textarea.form-control(type="text" v-model="editingApp.note" rows="4"
                            :placeholder="$t('comm.course_applications.attrs.note')")

      -# .alert.alert-info {{ $t('comm.course_applications.messages.courses_in_batch_message') }}

    %template(#footer="" v-if="editingApp")
      %button.btn.btn-default.me-3(@click="displayAppForm = false") {{ $t('lookups.actions.cancel')}}
      %button.btn.btn-primary(@click="submitApp" :disabled="!appIsValid")
        %template(v-if="editingApp.id") {{ $t('comm.lookups.actions.save') }}
        %template(v-else) {{ $t('comm.lookups.actions.add') }}
</template>

<script>
import Dialog from 'primevue/dialog'

export default {
  components: { Dialog },
  data() {
    return {
      displayAppForm: false,
      editingApp: null,
      teacherResults: [],
      teacherLoading: false
    }
  },
  computed: {
    title() {
      if (!this.editingApp) return ''
      return this.editingApp.id
        ? this.$t('comm.course_applications.headings.edit_course_in_batch_title')
        : this.$t('comm.course_applications.actions.add_course_in_batch')
    },
    appIsValid() {
      return this.editingApp.batch_count && this.editingApp.participation && this.editingApp.event_type
    }
  },
  methods: {
    participationList() {
      return ['old_student_ft', 'server_ft'].map((participation) => ({
        label: this.$t(`comm.course_applications.attrs.participation_values.${participation}`),
        value: participation
      }))
    },
    eventTypeList() {
      return this.$root.eventTypesForSelect
    },
    editApp(app) {
      this.editingApp = { ...app } // clone app
      this.displayAppForm = true
    },
    submitApp() {
      this.displayAppForm = false

      // TODO
      if (!this.editingApp.id) {
        this.$root.create('apps', this.editingApp)
      } else {
        this.$root.update('apps', this.editingApp)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  form {
    min-height: 230px; // enough space for event type picker
  }
</style>
