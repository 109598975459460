<template lang='haml'>
  .app-actions.btn-floating-sm
    / If a context app is selected
    %template(v-if="selectedAppContext.type == 'app'")
      / Contact Us
      %a.btn.btn-secondary(@click="$emit('contact', selectedAppContext.courseApplication.id)")
        .pi.pi-envelope.me-2
        {{ $t('comm.transmissions.index.contact') }}
      / Cancel App
      -# %a.btn.btn-outline-danger(v-if="cancellableAppContexts.includes(selectedAppContext)"
      -#   :href="cancelUrl(selectedAppContext)")
      -#   {{ $t('comm.lookups.action_radio_buttons.cancel') }}

    / If no context app is selected, display dropdown to choose context
    %template(v-if="!selectedAppContext.type || selectedAppContext.type == 'all'")
      / Contact Us
      .btn-group(v-if="appContexts.length > 0")
        %button.btn.btn-secondary.dropdown-toggle(type="button" data-bs-toggle="dropdown")
          .pi.pi-envelope.me-2
          {{ $t('comm.transmissions.index.contact') }}
        %ul.dropdown-menu
          %li(v-for="appContext in appContexts")
            %a.dropdown-item(@click="$emit('contact', appContext.courseApplication.id)")
              {{ appContext.label }}
      / Cancel App
      -# .btn-group(v-if="cancellableAppContexts.length > 0")
      -#   %button.btn.btn-outline-danger.dropdown-toggle(type="button" data-bs-toggle="dropdown")
      -#     {{ $t('comm.lookups.action_radio_buttons.cancel') }}
      -#   %ul.dropdown-menu
      -#     %li(v-for="appContext in cancellableAppContexts")
      -#       %a.dropdown-item(:href="cancelUrl(appContext)")
      -#         {{ appContext.label }}
</template>

<script>
import AppStateDisplay from 'mvip/pages/mvip_course_applications/AppStateDisplay.vue'

export default {
  components: { AppStateDisplay },
  props: ['contextOptions', 'selectedContext'],
  computed: {
    selectedAppContext() {
      if (this.appContexts.length == 1) return this.appContexts[0]
      return this.selectedContext
    },
    appContexts() {
      return this.contextOptions.filter((opt) => opt.type == 'app')
    },
    cancellableAppContexts() {
      return this.appContexts.filter((appContext) => appContext.courseApplication.event_start_date > new Date()
               && !['Cancelled', 'Discontinued', 'NewNoVacancy', 'NoVacancy', 'Refused'].includes(appContext.courseApplication.conf_state))
    }
  },
  methods: {
    cancelUrl(context) {
      return this.$routes.mvip_mvip_cancel_application_path({ id: context.courseApplication.id })
    }
  }
}
</script>

<style lang='scss' scoped>
  // @include media-breakpoint-up(md) {
  //   .app-status {
  //     float: right;
  //     display: flex;
  //     height: 2.5rem;
  //     flex-direction: row;
  //     align-items: center;
  //   }
  // }
</style>
