<template lang='haml'>
  .calm-password
    %Password(v-model="password" ref="input" name="user[password]" |
              class="password required user_password" |
              input-id="user_password" input-class="form-control password required" |
              toggle-mask=true :medium-regex="medRegEx" :strong-regex="strRegEx"
              :prompt-label="$t('comm.users.password.prompt')"
              :weak-label="$t('comm.users.password.weak')"
              :medium-label="$t('comm.users.password.medium')"
              :strong-label="$t('comm.users.password.strong')")
      -# Need to redefine content slot, don't know why other wise it just display "undefined"
      -# https://github.com/primefaces/primevue/blob/2.x/src/components/password/Password.vue#L8
      %template(#content)
        .p-password-meter
          %div(:class="$refs.input.strengthClass"
               :style="{'width': $refs.input.meter ? $refs.input.meter.width : ''}")
        .p-password-info {{$refs.input.infoText}}
      %template(#footer)
        %hr
        %p.p-mt-2 {{ $t('comm.users.password.requirements.label') }}
        %ul.p-pl-2.p-ml-2.p-mt-0{style:"line-height: 1.5"}
          %li {{ $t('comm.users.password.requirements.length') }}
          %li {{ $t('comm.users.password.requirements.special') }}
          %li {{ $t('comm.users.password.requirements.upper') }}
          %li {{ $t('comm.users.password.requirements.lower') }}
          %li {{ $t('lookups.user.privacy_policy.pw_15_chars') }}
</template>

<script>
import Password from 'primevue/password/password.common'

export default {
  components: { Password },
  data() {
    return {
      password: '',
      strRegEx: '^(((?=.*[a-z])(?=.*[A-Z])(?=.*[\_\\\-\=\/\.\!\@\#\$\%\^\&\*\(\)\?\{\}\\\*\<\>\+]))|((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])))(?=.{12,}).',
      medRegEx: '^((((?=.*[a-z])(?=.*[A-Z])(?=.*[\_\\\-\=\/\.\!\@\#\$\%\^\&\*\(\)\?\{\}\\\*\<\>\+]))|((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])))(?=.{8,})|\\w{14,})'
    } // med at least 8 chars with upcase, lowcase, [number or special char]  ||OR|| 15 chars or greater
  }
}
</script>

<style>
  .user_password {
    width: 100%;
  }
  .calm-password ~ .invalid-feedback {
    display: block;
  }
</style>
