<template lang='haml'>
  .btn-group.layout-picker
    %button.btn.btn-default.dropdown-toggle.text-capitalize(type="button" data-toggle="dropdown")
      %span(v-if="selectedLayout"
            v-html="$t('datatable.common.layout_with_name', { name: selectedLayout.name })")
      %template(v-else)
        {{ $t('datatable.common.layouts')}}
      %span.caret
    %ul.dropdown-menu.dropdown-menu-right
      %li.dropdown-header {{ $t('datatable.common.default_layouts')}}
      %li(v-for="layout in nonEditableLayouts" @click="$emit('layout-selected', layout)"
          :class="{active: layout.id == selectedLayout.id}")
        %a {{ layout.name }}
      %template(v-if="editableLayouts.length > 0")
        %li.divider(role="separator")
        %li.dropdown-header {{ $t('datatable.common.user_layouts')}}
        %li(v-for="layout in editableLayouts" @click="$emit('layout-selected', layout)"
            :class="{active: layout.id == selectedLayout.id}")
          %a.d-flex
            %span.flex-grow-1
              {{ layout.name }}
            %span.text-end(style="width: 2rem")
              %ConfirmButton(btn-class="ms-2 p-0" type="danger"
                            @confirmed="$emit('click-destroy', layout)"
                            :message="$t('datatable.common.delete_confirm', { name: layout.name })")
                %i.fa.fa-trash.destroy-layout(v-tooltip.right="$t('datatable.common.delete')")
</template>

<script>
import ConfirmButton from 'mvip/components/common/ConfirmButton'

export default {
  components: { ConfirmButton },
  props: ['layouts', 'selectedLayout'],
  computed: {
    nonEditableLayouts() {
      return (this.layouts || []).filter((l) => !l.editable)
    },
    editableLayouts() {
      return (this.layouts || []).filter((l) => l.editable)
    }
  }
}
</script>

<style lang='scss'>
  .layout-picker {
    .destroy-layout {
      display: none;
      float: right;
      color: var(--bs-danger) !important;
      opacity: .5;
      &:hover {
        opacity: 1;
      }
    }
    li:hover .destroy-layout {
      display: inline;
    }
    .dropdown-toggle span {
      i {
        font-style: normal;
        opacity: .7;
      }
    }
  }
</style>
