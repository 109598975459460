<template lang='haml'>
  %span.calm-select-container.form-control(:class="{multiple: attrs.multiple}")
    %v-select(v-model="selectedObject" v-bind="attrs" v-on:search-change="$emit('search-change', $event)" ref="vselect")
      %template(#noResult)
        {{ $t('lookups.global_search.no_result') }}
      %template(#noOptions)
        {{ $t('comm.lookups.general.search_placeholder') }}
      %template(#caret)
        .pi.pi-times.clear-button(@click.stop="clear"
                                  v-if="clearable && selectedObject && selectedObject != clearValue")
        .multiselect__caret-container
          %svg.multiselect__caret(xmlns="http://www.w3.org/2000/svg" width="14" height="10" role="presentation")
            %path(d="M9.211364 7.59931l4.48338-4.867229c.407008-.441854.407008-1.158247 0-1.60046l-.73712-.80023c-.407008-.441854-1.066904-.441854-1.474243 0L7 5.198617 2.51662.33139c-.407008-.441853-1.066904-.441853-1.474243 0l-.737121.80023c-.407008.441854-.407008 1.158248 0 1.600461l4.48338 4.867228L7 10l2.211364-2.40069z")
    %input(v-if="$attrs.name" type="hidden" :name="$attrs.name" :value="selectedValue")

</template>

<script>
import vSelect from 'vue-multiselect'

export default {
  props: ['value', 'clearable', 'clearValue'],
  components: { 'v-select': vSelect },
  inheritAttrs: false,
  data() {
    return {
      // multiselect works with a selectedObject, not just the selectedValue, so we do the same internally
      selectedObject: null
    }
  },
  watch: {
    selectedObject() {
      this.$emit('input', this.selectedValue)
    },
    value(newVal) {
      this.getSelectedObjectFromValue(newVal)
    }
  },
  mounted() {
    this.getSelectedObjectFromValue(this.value)
    this.$watch(
      function() {
        return this.$attrs.options
      },
      function(newVal, oldVal) {
        this.getSelectedObjectFromValue(this.value)
      }
    )
  },
  methods: {
    clear() {
      this.selectedObject = this.clearValue
      this.$refs.vselect.deactivate()
    },
    toggle() {
      this.$refs.vselect.isOpen ? this.$refs.vselect.deactivate() : this.$refs.vselect.activate()
    },
    getValueFromOption(option) {
      return option ? (this.attrs.trackBy ? option[this.attrs.trackBy] : option) : null
    },
    getSelectedObjectFromValue(val) {
      if ([undefined, '', null].includes(val)) return
      this.selectedObject = this.attrs.trackBy ? this.allOptions.find((o) => o[this.attrs.trackBy] == val) : val
    }
  },
  computed: {
    haveVModel() {
      return this.$listeners.input != null && this.$attrs[':value'] != null
    },
    selectedValue() {
      if (!this.selectedObject) return null
      if (Array.isArray(this.selectedObject)) return this.selectedObject.map((o) => this.getValueFromOption(o))
      return this.getValueFromOption(this.selectedObject)
    },
    allOptions() {
      if (this.hasOptionGroup) {
        return this.attrs.options.map((group) => group.group_values).flat()
      }
      return this.attrs.options
    },
    hasOptionGroup() {
      return this.$attrs.options && this.$attrs.options.length && this.$attrs.options.some((opt) => opt.group_values)
    },
    attrs() {
      const newAttrs = { ...this.$attrs } // cloning

      // handlings groups
      if (this.hasOptionGroup) {
        newAttrs['group-values'] = 'group_values'
        newAttrs['group-label'] = 'group_label'
        newAttrs.label = 'label'
        newAttrs.trackBy = 'value'
      }
      // handles options in the rails way : [ ['english', 'en'], ['French', 'fr']]
      else if (Array.isArray(newAttrs.options) && Array.isArray(newAttrs.options[0])) {
        newAttrs.options = newAttrs.options.map((array) => ({ label: array[0], value: array[1] }))
        newAttrs.label = 'label'
        newAttrs.trackBy = 'value'
      }
      // Handles rails label_method and value_method
      else {
        if (newAttrs.value_method && newAttrs.value_method != 'to_s') {
          newAttrs.trackBy = newAttrs.value_method
        }
        if (newAttrs.label_method && newAttrs.label_method != 'to_s') {
          newAttrs.label = newAttrs.label_method
        }
      }

      // fix prop name
      if (newAttrs['track-by']) newAttrs.trackBy = newAttrs['track-by']
      newAttrs.allowEmpty = newAttrs.allow_empty == 'true'
      // use our own hidden input to keep the name
      newAttrs.name = null
      // Clear multiselect default translations
      newAttrs.selectLabel = ''
      newAttrs.deselectLabel = ''
      newAttrs.selectedLabel = ''
      if (!newAttrs.placeholder) newAttrs.placeholder = '' // do not use default multiselect placeholder

      return newAttrs
    }
  }
}
</script>

<style lang='scss'>
  $transition-timing-function: cubic-bezier(1.000, -0.115, 0.975, 0.855);
  $open-indicator-size: .7;

  .calm-select-container.form-control {
    padding: 0;
    .multiselect__input, .multiselect__single {
      background-color: transparent;
    }
    .multiselect, .multiselect__input, .multiselect__single {
      font-size: 1rem;
    }
    .multiselect {
      color: inherit;
      min-height: 2.25rem;
      display: flex;
      position: relative;
      align-items: center;
      box-sizing: border-box;

      &.multiselect--disabled .multiselect__caret-container {
        visibility: hidden;
      }

      .multiselect__option--selected {
        font-weight: normal;
        color: var(--bs-primary);
        background-color: var(--bs-gold-100);
      }

      .multiselect__option--highlight {
        background-color: var(--bs-primary);
        color: white;
      }

      .multiselect__tags {
        flex: 1 auto;
        border: none;
        min-height: unset;
        padding: var(--bs-input-padding);
        font-size: inherit;
        background-color: transparent;
        overflow: hidden;

        .multiselect__tag {
          border-radius: 3px;
          margin-right: 5px;
          margin-bottom: 0;
          background-color: var(--bs-primary);
          color: white;

          .multiselect__tag-icon {
            line-height: 1.6rem;
            &:hover { background-color: var(--bs-gold-600) }
            &:after { font-size: 18px; color: white; }
          }
        }
        .multiselect__single, input {
          padding-left: 0;
          margin-bottom: 0;
          line-height: 1.5;
          height: 1.5rem;
        }
      }

      .multiselect__option--group {
        color: var(--bs-dark) !important;
        font-weight: bold;
        color: var(--bs-primary) !important;
        // font-weight: 300;
        background: var(--bs-light) !important;
        background: transparent !important;
        font-size: .8em;
      }

      .multiselect__placeholder {
        margin: 0;
        padding: 0;
      }

      .multiselect__caret-container {
        order: 2;
        padding: var(--bs-input-padding);

        .multiselect__caret {
          fill: rgba(60,60,60,.5);
          transform: scale($open-indicator-size);
          transition: transform 150ms $transition-timing-function;
          transition-timing-function: $transition-timing-function;
        }
      }
      &.multiselect--active .multiselect__caret {
        transform: rotate(180deg) scale($open-indicator-size);
      }

      .multiselect__content-wrapper {
        align-self: end;
      }
      &:not(.multiselect--above) .multiselect__content-wrapper {
        top: 100%;
        left: -1px;
        width: calc(100% + 2px);
      }
    }

    &.multiple .multiselect__tags{
      padding-left: .5rem;
    }

    .clear-button {
      order: 2;
      font-size: .7em;
      color: rgba(60,60,60,.5);
      font-weight: bold;
      padding: .7em 0 .7em 1em;
      cursor: pointer;
      &:hover {
        color: rgba(60,60,60,.8);
      }
    }
  }
  .form-floating {
    .calm-select-container {
      padding: 0 !important;
    }
    .multiselect__tags {
      padding: var(--bs-form-floating-padding-y) var(--bs-form-floating-padding-x);
      .multiselect__caret-container {
        margin-top: -1.2rem;
      }
    }
    > .form-control {
      &:focus,
      &:not(:placeholder-shown) {
        .multiselect__tags {
          padding-top: var(--bs-form-floating-input-padding-t);
          padding-bottom: var(--bs-form-floating-input-padding-b);
        }
      }
      &:-webkit-autofill {
        .multiselect__tags {
          padding-top: var(--bs-form-floating-input-padding-t);
          padding-bottom: var(--bs-form-floating-input-padding-b);
        }
      }
    }
  }
</style>
