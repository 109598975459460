import { nfd } from 'unorm'

export function removeDiactrics(str) {
  return nfd(str).replace(/[\u0300-\u036f]/g, '')
}

export function formatForSearch(str) {
  return removeDiactrics(str.replace(/\s+/, '')).toLowerCase()
}

export function toUnderscore(str) {
  return str.replace(/([A-Z])/g, '_$1').replace(/^_/, '').toLowerCase()
}

// equivalent of Rails string.underscore, e.g. so we can translate the event types
// maybe better tha above one, not sure, gt it off stackoverflow
export function snakeCase(string) {
  return string.replace(/\W+/g, ' ')
    .split(/ |\B(?=[A-Z])/)
    .map((word) => word.toLowerCase())
    .join('_')
}

export function stripTags(string) {
  return (new String(string)).replace(/<\/?[^>]+(>|$)/g, '')
}

export function transformStringWithNumbersForSorting(value) {
  if (!value) return 'zzzzzzzzzzzzzzzz' // put at the end of sorting
  const parts = value.match(/\d+|\D+/g)
  const newParts = []
  parts.forEach((part) => {
    const isDigits = /\d/.test(part)
    let newPart = part
    // adds leading zero, so 42 is vonverted to 000000042, then sorting works nicely
    if (isDigits) newPart = part.padStart(10, '0')
    newParts.push(newPart)
  })
  return newParts.join('')
}

// https://cwestblog.com/2013/04/10/javascript-comparing-and-sorting-strings-with-numbers/
// Add cmpStringsWithNumbers to the global namespace.  This function takes to
// strings and compares them, returning -1 if `a` comes before `b`, 0 if `a`
// and `b` are equal, and 1 if `a` comes after `b`.
export function compareStringsWithNumbers(a, b) {
  // Regular expression to separate the digit string from the non-digit strings.
  const reParts = /\d+|\D+/g
  // Regular expression to test if the string has a digit.
  const reDigit = /\d/

  // Get rid of casing issues.
  a = a.toUpperCase()
  b = b.toUpperCase()

  // Separates the strings into substrings that have only digits and those
  // that have no digits.
  const aParts = a.match(reParts)
  const bParts = b.match(reParts)

  // Used to determine if aPart and bPart are digits.
  let isDigitPart

  // If `a` and `b` are strings with substring parts that match...
  if (aParts && bParts
      && (isDigitPart = reDigit.test(aParts[0])) == reDigit.test(bParts[0])) {
    // Loop through each substring part to compare the overall strings.
    const len = Math.min(aParts.length, bParts.length)
    for (let i = 0; i < len; i += 1) {
      let aPart = aParts[i]
      let bPart = bParts[i]

      // If comparing digits, convert them to numbers (assuming base 10).
      if (isDigitPart) {
        aPart = parseInt(aPart, 10)
        bPart = parseInt(bPart, 10)
      }

      // If the substrings aren't equal, return either -1 or 1.
      if (aPart != bPart) {
        return aPart < bPart ? -1 : 1
      }

      // Toggle the value of isDigitPart since the parts will alternate.
      isDigitPart = !isDigitPart
    }
  }

  // Use normal comparison.
  return (a >= b) - (a <= b)
}
