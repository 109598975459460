import Dialog from 'primevue/dialog'
import { post } from 'helpers/core/ajax-utils'

export default {
  components: { DialogComponent: Dialog },
  data: {
    displayForgetDialog: false,
    displayRequestDialog: false
  },
  methods: {
    requestData(e) {
      e.target.disabled = true
      post(Routes.mvip_request_data_path(gon.locale)).then(() => {
        this.displayRequestDialog = false
      })
    }
  }
}
