import ActiveApplication from './ActiveApplication.vue'

export default {
  components: { ActiveApplication },
  data: { apps: [] },
  mounted() {
    this.apps = gon.course_applications.map((app) => {
      // Add T00:00:00Z to force UTC timezone Refs #5828
      if (app.start_date) app.start_date = new Date(`${app.start_date}T00:00:00Z`)
      if (app.end_date) app.end_date = new Date(`${app.end_date}T00:00:00Z`)
      return app
    })
  }
}
