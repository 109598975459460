export default {
  methods: {
    transformMessages(messages) {
      let result = messages.map(this.transformMessage)
      result = result.map((msg) => this.calculateActions(msg, messages))
      return result
    },
    transformMessage(msg) {
      msg.display_sent_at = new Date(msg.display_sent_at)
      if (this.course_applications) {
        msg.course_application = this.course_applications.find((app) => app.id == msg.app_id) || {}
      } else if (this.app) {
        msg.course_application = this.app
      }
      if (msg.app_id) {
        msg.context = msg.app_id
        msg.context_display = (msg.course_application || {}).display
      } else {
        const aContext = msg.topic == 'publication' ? msg.topic : 'other'
        msg.context = aContext
        msg.context_display = this.$t(`comm.transmissions.attrs.topic_values.${aContext}`)
      }
      return msg
    },
    calculateActions(msg, allMessages) {
      if (!msg.course_application) return msg

      // Is this message have a form attached that need to be complete
      msg.haveFormToComplete = false
      if (msg.course_application.event_start_date > new Date() && msg.form_status == 'form_not_completed') {
        // If a form with same template_code have been completed in the meantime, don't emphasis
        if (!allMessages.some((form) => form.type == 'Comm::Form'
                                     && form.template_code == msg.form_code
                                     && form.display_sent_at > msg.display_sent_at)) {
          // in case multiple messages have been sent to ask to complete same form, we want to emphasis
          // only last message sent
          const lastMsgWithFormCode = allMessages.filter((otherMsg) => otherMsg.app_id == msg.app_id
                                                                    && otherMsg.form_status == msg.form_status
                                                                    && otherMsg.form_code == msg.form_code)[0]
          if (lastMsgWithFormCode && lastMsgWithFormCode.id == msg.id) msg.haveFormToComplete = true
        }
      }
      // Available actions
      msg.actions = {
        answer: msg.haveFormToComplete,
        reply: msg.status == 'registrar_sent' && msg.course_application.id // refs #4906 reply only if course_app is present
      }
      return msg
    }
  }
}
