// Using JsRoute Gem we convert make rails routes available from javascript
// this plugin adds a $routes attribute on anay Vue instance, and default the local argument with the locale
export default {
  install(Vue, options) {
    const routes = {}
    if (typeof Routes === 'undefined') {
      console.error('Routes is not defined')
      return
    }
    for (const routeName of Object.keys(Routes)) {
      routes[routeName] = function() {
        // Adds locale parameters at first argument
        const args = Array.from(arguments)
        args.unshift(gon.locale)
        try {
          return Routes[routeName].apply(null, args)
        } catch (error) {
          console.error('Error while building route', routeName, 'with arguments', args, error)
          return ''
        }
      }
    }
    Vue.prototype.$routes = routes
  }
}
