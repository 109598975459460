<template lang='haml'>
  %DataTable(v-bind="tableBindings" v-on="tableListeners"
             @row-click="handleRowClick"
             editMode="cell" @cell-edit-complete="onCellEditComplete" class="editable-cells-table"
             @cell-edit-init="onCellEditInit" @cell-edit-cancel="onCellEditCancel"
             ref="table")
    %template(#header)
      .d-flex.flex-wrap.gap-2.mb-3
        .flex-grow-1.flex-shrink-0
          %slot(name="header")
        .btn.btn-warning(type="button" @click="clearFilters()"
          v-if="clearAllFiltersBtn && Object.values(filters).some(filter => filter.value)")
          .pi.pi-filter-slash.me-2
          {{ $t('datatable.common.clear_filters') }}
        %ColumnsPicker(:activated="columnsToggle" :columns="columns"
                       :fieldsToHide="fieldsToHide" :tableKey="tableKey" |
                       :columns-to-display.sync="columnsToDisplay")
    %template(#empty)
      .text-center {{ $t('datatable.common.no_result') }}
    %Column(v-for="column of columnsToDisplay" v-bind="column" header="" :key="column.field"
            v-if="!column.screen || screen[column.screen]")
      %template(#header="")
        %span.p-column-title(:title="column.headerTitle") {{ column.header }}
      %template(#filter="slotProps")
        %component(v-bind="filterBindings(column, slotProps)")
      %template(#body="{data,field}")
        %i.fas.fa-circle-notch.fa-spin(v-if="data._loadingFields.includes(field)")
        %template(v-else)
          %RoomDisplay(v-if="column.type == 'room'" :app="data" :apps="items")
          %template(v-else-if="data[field] instanceof Date")
            %span(:title="column.dateFullOnHover ? $l(data[field], 'full') : null")
              {{ $l(data[field], column.dateFormat) }}
              %template(v-if="column.appendField && data[column.appendField]")
                , {{ data[column.appendField] }}
          %span(v-else v-html="data[field]")
      %template(#editor="{data}" v-if="column.editable")>
        %RoomInput.flex-grow-1.align-items-center(v-if="column.type == 'room'" autofocus
                                                  :app="data" :apps="items"
                                                  v-model="data[column.field]")
        .input-group.btn-group.d-flex.flex-grow-1(v-else  @click.prevent.stop=""
                                                  style="margin: 0 -.5rem")
          %textarea.form-control.p-2(v-model="data[column.field]")
          .d-flex.flex-column-reverse
            %CalmButton.btn-default.btn-sm.border-start-0.flex-grow-1(
              icon="fa fa-times" @click.stop="simulateKeyDown(27)")
            %CalmButton.btn-primary.btn-sm.flex-grow-1(
              icon="fa fa-check" @click.stop="simulateKeyDown(13)")
    %slot
</template>

<script>
import { get, post, handleAjaxError } from 'helpers/core/ajax-utils'
import { stripTags } from 'helpers/string_helper'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Vue from 'vue'
import ColumnsPicker from './ColumnsPicker.vue'

export default {
  components: { DataTable, Column, ColumnsPicker },
  events: {
    'row-clicked': "override primevue 'row-click' to take in account cell editing",
    'cell-after-edit': 'callback after the cell edit complete and ajax return successfully'
  },
  props: {
    route: {
      type: String,
      required: true
    },
    lazy: {
      type: Boolean,
      default: false
    },
    urlParams: {
      type: Object,
      default() { return {} }
    },
    columnsToggle: {
      type: Boolean,
      default: false
    },
    clearAllFiltersBtn: {
      type: Boolean,
      default: false
    },
    fieldsToHide: {
      type: Array,
      default() { return [] }
    },
    transformData: {
      type: Function,
      default(items) { return items }
    }
  },
  data() {
    return {
      tableKey: null,
      // filters
      filters: {},
      // columns
      columns: [],
      columnsToDisplay: [],
      fieldsValues: {},
      // Internal properties
      items: [],
      itemsRetrieved: {},
      totalRecords: 0,
      perPage: 10,
      first: 0,
      loading: false,
      pendingRequest: false,
      editingItemId: null,
      editLoading: []
    }
  },
  computed: {
    // Url params to get the data
    defaultUrlParams() {
      const result = { format: 'json' }
      if (this.lazy) {
        result.length = this.perPage
        result.start = this.first
      }
      return result
    },
    allUrlParams() {
      return { ...this.defaultUrlParams, ...this.urlParams }
    },
    stringifiedParams() {
      return JSON.stringify(this.allUrlParams)
    },
    // DataTable html attributes
    tableBindings() {
      return {
        ...{
          value: this.items,
          lazy: this.lazy,
          loading: this.loading || this.columnsLoading,
          dataKey: 'id',
          // Filters
          filters: this.filters,
          // Paginator
          paginator: this.totalRecords > this.perPage,
          rows: this.perPage,
          totalRecords: this.totalRecords,
          paginatorTemplate: 'CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink',
          rowsPerPageOptions: [10, 25, 50, 150],
          currentPageReportTemplate: 'Showing {first} to {last} of {totalRecords}',
          // Sort
          removableSort: true,
          // Others
          responsiveLayout: 'scroll',
          stateKey: this.tableKey,
          stateStorage: 'local'
        },
        ...this.$attrs
      }
    },
    tableListeners() {
      return {
        ...{
          page: (event) => { this.first = event.first },
          'update:filters': (event) => {
            Object.entries(event).forEach(([field, filter]) => {
              // If the filter is not in current columns list, just ignore. It prevent situation where a filter
              // is saved but then the columns is not displayed anymore #5164
              if (!this.columns.find((c) => c.field === field)) return
              // Only update the value, otherwise the matchmode is saved inside sessionStore and then
              // we cannot override it by changing the config in ruby datatable
              // If we want to let user choose the matchmode and remember it, following line will need to be changed
              this.filters[field] = { ...this.filters[field], ...{ value: filter.value } }
            })
          },
          'state-restore': (event) => {
            if (this.$attrs.rows) return // rows is specified as a props, so keeping this value

            this.perPage = event.rows
            const options = this.tableBindings.rowsPerPageOptions
            if (!options.includes(this.perPage)) {
              this.perPage = options[0]
            }
          }
        },
        ...this.$listeners
      }
    }
  },
  beforeMount() {
    if (gon.datatable) {
      this.tableKey = gon.datatable.key
      this.filters = Object.fromEntries(gon.datatable.columns.map((col) => [col.field, col]))
      this.columns = gon.datatable.columns.map((col) => {
        if (col.width) {
          col.headerClass = `w-${col.width}`
          col.bodyClass = `w-${col.width}`
          col.filterHeaderClass = `w-${col.width}`
          delete col.width
        }
        return col
      })
    }
    // Specify the umber of rows with prop
    if (this.$attrs.rows) this.perPage = this.$attrs.rows
  },
  methods: {
    retrieveData() {
      if (this.loading) {
        this.pendingRequest = true
        return
      }
      this.pendingRequest = false
      this.items = []
      const requestParams = this.stringifiedParams
      if (this.itemsRetrieved[requestParams]) {
        // give time for the view to redraw with loading and no items
        this.handleResult(this.itemsRetrieved[requestParams])
      } else {
        this.loading = true
        const url = this.$routes[this.route](this.allUrlParams)
        get(url)
          .then((result) => {
            this.itemsRetrieved[requestParams] = result.data
            this.handleResult(result.data)
          }).catch((error) => handleAjaxError(error, this))
      }
    },
    handleResult(result) {
      this.items = result.data.map((data) => ({ ...data, ...{ _loadingFields: [] } }))
      this.items = this.transformData(this.items)
      this.totalRecords = result.recordsCount
      this.loading = false
      if (this.pendingRequest) {
        this.retrieveData()
      }
    },
    calculateFieldsValues() {
      // Group all unique value for each field. this is used only for dropdown filter with auto options
      const fields = this.columns
        .filter((col) => col.filter == 'dropdown' && !col.options)
        .map((col) => col.field)
      fields.forEach((field) => {
        const values = this.items.map((data) => stripTags(data[field])).filter((v) => v && v !== 'null')
        Vue.set(this.fieldsValues, field, [...new Set(values)])
      })
    },
    filterBindings(column, slotProps) {
      const col = { ...column }
      col.is = column.filter ? `${column.filter}-filter` : { render: () => '' }
      col.filterModel = slotProps.filterModel
      col.filterCallback = slotProps.filterCallback
      col.options ||= this.fieldsValues[col.field]
      return col
    },
    clearFilters() {
      for (const field in this.filters) {
        this.filters[field].value = null
      }
    },
    onCellEditComplete(event) {
      const { data, newValue, field } = event
      data._loadingFields.push(field)
      post(this.$routes.update_datatable_cell_path(), {
        class: this.tableKey,
        id: data.id,
        field,
        value: newValue
      }).then((result) => {
        const oldValue = data[field]
        data[field] = newValue
        this.$emit('cell-after-edit', { data, field, newValue, oldValue })
        data._loadingFields = data._loadingFields.filter((f) => f != field)
      }).catch((error) => {
        handleAjaxError(error, this)
        data._loadingFields = data._loadingFields.filter((f) => f != field)
      })
      // if click to edit new cell, the complete event of the first cell arrive after the init of the new cell,
      // so we reset the editing only if it's the same id
      if (this.editingItemId == data.id) this.editingItemId = null
    },
    onCellEditCancel() {
      this.editingItemId = null
    },
    onCellEditInit(event) {
      // Prevent row click
      this.editingItemId = event.data.id
    },
    simulateKeyDown(key) {
      // Hack to trigger keydown on editing cell component
      this.$children[0].$children.find((v) => v._vnode.tag == 'tbody')
        .$children.find((td) => td.d_editing)
        .onKeyDown({ which: key })
    },
    handleRowClick(event) {
      // Disable row click while editing
      if (!this.editingItemId) this.$emit('row-clicked', event)
    }
  },
  watch: {
    allUrlParams() {
      this.retrieveData()
    },
    items() {
      this.calculateFieldsValues()
    }
  }
}
</script>

<style lang='scss' scoped>
</style>
