<template lang='haml'>
.course-history
  %h2.d-flex.align-items-center.mb-3
    .me-auto
      {{ $t('comm.course_applications.headings.history') }}
    .ff-normal
      %template(v-if="screen.isSM")
        .dropdown
          %button.btn.btn-secondary.dropdown-toggle.rounded-circle.p-2(aria-expanded="false"
            data-bs-toggle="dropdown" type="button")
            .pi.pi-plus
          %ul.dropdown-menu
            %li
              %a.dropdown-item(@click="addCustomApp")
                {{ $t('comm.course_applications.actions.add_course') }}
            %li
              %a.dropdown-item(@click="addCustomAppInBatch")
                {{ $t('comm.course_applications.actions.add_course_in_batch') }}
      %template(v-else)
        %a.btn.btn-outline-secondary(@click="addCustomAppInBatch")
          .pi.pi-plus.me-1
          {{ $t('comm.course_applications.actions.add_course_in_batch') }}

        %a.btn.btn-secondary.ms-3(@click="addCustomApp")
          .pi.pi-plus.me-1
          {{ $t('comm.course_applications.actions.add_course') }}

  %Data-Table(:value="previousApps" :paginator="previousApps.length > 10" :rows="10" data-key="id"
              responsiveLayout="scroll")
    %Column(field="id" v-if="screen.isSM")
      %template(#body="{ data }")
        %span {{ $root.translateEventType(data.event_type) }}
        %span= " - "
        %span.ms-1
          %template(v-if="['Completed', 'Arrived'].includes(data.conf_state)")
            {{ $t('comm.course_applications.attrs.participation_values.' + data.participation) }}
          %template(v-else)
            {{ $t('lookups.course_app_confirmation_states.shorter_display_name.' + data.conf_state) }}
        .subtitle
          %template(v-if="data.is_batch")
            {{ $t('comm.course_applications.attrs.batch_count_description', { count: data.batch_count }) }}
          %template(v-else)
            %span.text-capitalize(v-if="data.start_date")
              {{ data.start_date.toLocaleDateString(locale, {month: 'long', year: "numeric" }) }}
            %span.text-capitalize(v-if="data.venue")
              {{ data.venue.length > 1 ? ' - ' + data.venue : '' }}
            %span(v-if="data.teacher && data.teacher.name")
              {{ data.teacher.name.length > 1 ? ' - ' + data.teacher.name : '' }}
    %Column(field="dates" :header="$t('comm.course_applications.attrs.dates')" v-if="screen.upMD")
      %template(#body="{ data }")
        %template(v-if="data.start_date")
          %span {{ data.start_date.toLocaleDateString(locale, {year: "numeric" }) }}
          %span.ms-1 {{ data.start_date.toLocaleDateString(locale, {month: 'short' }) }}
    %Column(field="event_type" :header="$t('comm.course_applications.attrs.event_type')" v-if="screen.upMD")
      %template(#body="{ data }")
        {{ $root.translateEventType(data.event_type) }}
        %span.badge.text-bg-light.ms-1(v-show="data.event_type != 'LongTermService'")
          %template(v-if="['Completed', 'Arrived'].includes(data.conf_state)")
            {{ $t('comm.course_applications.attrs.participation_values.' + data.participation) }}
          %template(v-else)
            {{ $t('lookups.course_app_confirmation_states.shorter_display_name.' + data.conf_state) }}
    %Column(field="infos" v-if="screen.upMD" :header="`${$t('comm.course_applications.attrs.location')} - ${$t('comm.course_applications.attrs.teacher')}`")
      %template(#body="{ data }")
        %template(v-if="data.is_batch")
          {{ $t('comm.course_applications.attrs.batch_count_description', { count: data.batch_count }) }}
        %template(v-else)
          %span.text-capitalize(v-if="data.venue")
            {{ data.venue }}
          %span(v-if="data.venue && data.teacher && data.teacher.name.length > 1")= " - "
          %span.text-capitalize(v-if="data.teacher && data.teacher.name")
            {{ data.teacher.name }}
    %Column(header-style="width: 6rem; flex-shrink: 0" body-class="text-end")
      %template(#body="{ data }")
        .btn.btn-sm.d-inline(v-if="data.editable" @click="editApp(data)")
          %i.pi.pi-pencil
        %ConfirmButton(btn-class="btn-sm d-inline" type="danger" v-if="data.editable"
                       @confirmed="$root.destroy('apps', data)")
          %i.pi.pi-times
    %template(#empty)
      .text-center {{ $t('datatable.common.no_result') }}

  %CourseHistoryForm(ref="form")
  %CourseHistoryBatchForm(ref="batchForm")

</template>

<script>
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import CourseHistoryForm from './CourseHistoryForm'
import CourseHistoryBatchForm from './CourseHistoryBatchForm'

export default {
  components: { DataTable, Column, CourseHistoryForm, CourseHistoryBatchForm },
  computed: {
    previousApps() {
      return this.$root.apps
        .filter((app) => app.is_batch || app.start_date < new Date())
        .sort((a, b) => {
          if (!a.start_date || !b.start_date) return a.start_date ? 1 : -1
          return b.start_date - a.start_date
        })
    }
  },
  methods: {
    addCustomApp() {
      this.$refs.form.editApp({
        editable: true,
        conf_state: 'Completed'
      })
    },
    addCustomAppInBatch() {
      this.$refs.batchForm.editApp({
        editable: true,
        conf_state: 'Completed',
        is_batch: true
      })
    },
    editApp(app) {
      app.is_batch ? this.$refs.batchForm.editApp(app) : this.$refs.form.editApp(app)
    }
  }
}
</script>
