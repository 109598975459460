<template lang='haml'>
.segments-renderer
  %template(v-for="(segment,i) in segments")
    %template(v-if="displaySegment(segment)")
      %component(:is="componentName(segment)" :segment="segment" v-model="values[segment.ident]" :action="action" :key="i")
</template>

<script>
import ChoiceSegment from './segments/ChoiceSegment'
import ReplySegment from './segments/ReplySegment'
import AttachmentSegment from './segments/AttachmentSegment'
import TextSegment from './segments/TextSegment'
import CheckboxSegment from './segments/CheckboxSegment'
import FormSegment from './segments/FormSegment'
import DoctorsFormSegment from './segments/DoctorsFormSegment'

export default {
  components: {
    ChoiceSegment, ReplySegment, TextSegment, AttachmentSegment, CheckboxSegment,
    FormSegment, DoctorsFormSegment
  },
  props: ['segments', 'action', 'isDoctor'],
  data() {
    return { values: {} }
  },
  methods: {
    // Handles the "show_when" value
    displaySegment(segment) {
      if (segment.doctor_only && this.isDoctor !== true) return false
      if (!segment.show_when) return true
      const conditions = segment.show_when.split('&&').map((c) => c.trim())
      return conditions.every((condition) => {
        const parts = condition.split('=')
        if (parts.length != 2) return true
        return this.values[parts[0]] == parts[1]
      })
    },
    componentName(segment) {
      if (segment.class_name == 'ActionSegment') return 'ChoiceSegment'
      return segment.class_name || 'TextSegment'
    }
  },
  mounted() {
    for (const segment of this.segments) {
      if (segment.ident) this.$set(this.values, segment.ident, segment.value)
    }
  }
}

</script>

<style lang='scss'>
  // Customize display inside myCourses
  .vds-body .segments-renderer {
    p {
      margin: 1em 0; // restore default p margins (was ovewritten by bootstrap)
    }

    h1, h2, h3, h4, h5, h6 {
      margin-top: 1em;
    }

    h2 {
      font-size: 1.9rem;
      color: var(--bs-secondary);
    }
    h3 {
      font-size: 1.4rem;
    }
    h4 {
      font-size: 1.2rem;
    }
    h5 {
      font-size: 1rem;
    }
  }
</style>
