<template lang='haml'>
  .languages-container
    %h2.mt-4.mb-3.d-flex.align-items-center
      .me-auto
        {{ $t("comm.people.form.headers.languages") }}
        %span.subtitle {{ $t("comm.people.form.headers.languages_subtitle") }}
      .btn-add-language.btn.pi.pi-plus.rounded-circle.bg-secondary.text-white(v-on:click="addLanguage()")
    %draggable(v-model="$root.personLanguages" group="people")
      %Person-Language(v-for="(personLanguage, index) in $root.personLanguages" :index="index" :person-language="personLanguage" :key="personLanguage.id" :assignable-languages="assignableLanguages" @delete="removeLanguage($event)")
    %input(type="hidden" name="person[languages]" :value="inputValue")
</template>

<script>
import draggable from 'vuedraggable'
import PersonLanguage from './PersonLanguage.vue'

export default {
  components: { PersonLanguage, draggable },
  data() {
    return { createdLanguagesCount: 0 }
  },
  methods: {
    addLanguage() {
      this.$root.personLanguages.push({
        id: `new_${this.createdLanguagesCount}`,
        isNew: true
      })
      this.createdLanguagesCount += 1
    },
    removeLanguage(lang) {
      this.$root.personLanguages.splice(this.$root.personLanguages.indexOf(lang), 1)
    }
  },
  computed: {
    inputValue() {
      const result = this.$root.personLanguages
        .filter((lang) => lang.language_id && lang.proficiency)
        .map((lang, index) => ({
          id: lang.isNew ? null : lang.id,
          language_id: lang.language_id,
          publication_preference: index + 1,
          proficiency: lang.proficiency
        }))
      return JSON.stringify(result)
    },
    assignedLanguageIds() {
      return this.$root.personLanguages.filter((obj) => !obj.isNew).map((obj) => obj.language_id)
    },
    assignableLanguages() {
      return this.$root.languages.filter((obj) => !this.assignedLanguageIds.includes(obj[1]))
    }
  }
}
</script>

<style lang='scss' scoped>
  .subtitle {
    opacity: .8;
    font-size: 1rem;
    margin-left: 5px;
  }
  .btn-add-language {
    padding: 0.75rem;
    font-size: 1rem;
    font-weight: bold;
  }
</style>
