import MessagesList from './MessagesList.vue'
import messagesTransformMixin from './messages-transform-mixin'

export default {
  mixins: [messagesTransformMixin],
  components: { MessagesList },
  data: { messages: [] },
  mounted() {
    this.messages = this.transformMessages(gon.messages)
  }
}
