<template lang='haml'>
  %span.text-segment(v-html="displayValue")
</template>

<script>
export default {
  props: ['segment', 'action'],
  computed: {
    displayValue() {
      return this.segment.value
        .replace(
          /<blockquote/gi,
          `<span class="btn btn-default display-blockquote"
                onclick="$(this).next('blockquote').toggle()"
                title="${this.$t('lookups.message.display_more')}">
          <i class="fa fa-ellipsis-h"></i>
          </span>
          <blockquote
        `
        )
    }
  }
}
</script>

<style lang='scss'>
::v-deep .context-message {
  color: #57a8ff;
  font-weight: bold;
}
.text-segment * {
  font-family: inherit !important;
}
</style>
