import { axios, handleAjaxError } from 'helpers/core/ajax-utils'
import CalmDatatable from 'components/datatable/CalmDatatable'
import Column from 'primevue/column'
import Dialog from 'primevue/dialog'

export default {
  components: { CalmDatatable, Column, DialogComponent: Dialog },
  data() {
    return {
      filters: {
        publication_name: undefined,
        location_id: undefined,
        language_id: undefined,
        publication_type: undefined
      },
      languages: null,
      languagesReady: false,
      displayPublicationDialog: false,
      publicationToDisplay: {}
    }
  },
  computed: {
    languagesWithBlankOption() {
      return [{ label: this.$t('menus.languages_all'), id: 'all' }].concat(this.languages || [])
    },
    urlParams() { // override urlParams
      const result = {
        publication_name: this.filters.publication_name,
        location_id: this.filters.location_id,
        language_id: this.filters.language_id,
        publication_type: this.filters.publication_type != 'all' ? this.filters.publication_type : '',
        iframe: gon.iframe
      }
      return result
    }
  },
  methods: {
    openPublication(event) {
      this.publicationToDisplay = event.data

      if (this.publicationToDisplay.html) {
        this.displayPublicationDialog = true
      } else {
        // fetch html with ajax
        axios.get(this.$routes.mvip_show_archived_publication_path(this.publicationToDisplay.id, { bare_html: true })).then((result) => {
          this.publicationToDisplay.html = result.data
          this.displayPublicationDialog = true
        }).catch((error) => handleAjaxError(error, this))
        // If ajax takes too long, display the spinner-loader by opening the dialog
        setTimeout(() => { this.displayPublicationDialog = true }, 400)
      }
    }
  },
  watch: {
    'filters.location_id': function(newVal, oldVal) {
      this.languagesReady = false
      axios.get(this.$routes.mvip_languages_for_location_path(newVal))
        .then((result) => {
          this.languages = result.data
          if (this.filters.language_id != 'all') {
            let selectedLang = this.languages.find((lang) => this.filters.language_id == lang.id)
            selectedLang ||= this.languages.find((lang) => this.locale == lang.iso_code)
            selectedLang ||= this.languages.first || {}
            this.filters.language_id = selectedLang.id
          }
          this.languagesReady = true
        })
        .catch((error) => handleAjaxError(error, this))
    },
    'filters.language_id': function(newVal) {
      if (newVal === null) this.filters.language_id = 'all'
    },
    urlParams() {
      if (window.history && window.history.replaceState) { // only do this where supported, it is not critical, so otherwise do nothing
        let params = Object.entries(this.urlParams)
          .filter(([key, val]) => !!val)
          .map(([key, val]) => `${key}=${val}`).join('&')
        if (params) params = `?${params}`
        window.history.replaceState(null, '', params)
      }
    }
  }
}
