<template lang='haml'>
  / This component is only used to preview segments in the location_variable_helper
  %a(:href="`#${segment.ident}_Form`" :title="segment.ident")
    {{ translation }}
</template>

<script>
export default {
  props: ['segment', 'action'],
  computed: {
    translation() {
      // reproduce the code in replace_form_tag_with_link
      const linkName = this.segment.link_name || 'questions'
      if (this.$te(`comm.lookups.auto_calculated_links.${linkName}`)) {
        return this.$t(`comm.lookups.auto_calculated_links.${linkName}`)
      }

      return linkName
    }
  }
}
</script>
