<template lang='haml'>
  / Table
  %DataTable(v-bind="tableBindings" v-on="tableListeners"
             sortField="sent_at" :sortOrder="-1"
             responsiveLayout="scroll")
    %Column(field="transaction_date")
      %template(#body="slotProps")
        {{ slotProps.data.sent_at.toLocaleDateString(locale, {month: 'long', year: 'numeric'})}}

    %Column(:expander="true" bodyClass="expander")

    / Content
    %template(#expansion="slotProps")
      .full-page-print
        .btn.btn-print.rounded-circle.pi.pi-print.fs-2.float-end.d-print-none(@click="printReport()")
        %SegmentsRenderer(:segments="slotProps.data.segments" action="show")
</template>

<script>
import DatatableExpandableMixin from 'mvip/helpers/datatable-expandable-mixin'
import SegmentsRenderer from 'components/letters/SegmentsRenderer'

export default {
  mixins: [DatatableExpandableMixin],
  components: { SegmentsRenderer },
  methods: {
    printReport() {
      window.print()
    }
  }
}
</script>

<style lang='scss' scoped>
  ::v-deep .p-datatable-thead {
    display: none;
  }
  .btn-print {
    padding: .75rem;
    background-color: var(--bs-gray-200);
    line-height: 1;
  }
</style>
