<template lang='haml'>
  %Dialog(:header="title" :modal="true" :visible.sync="displayAppForm")
    %form.needs-validation(v-if="editingApp" novalidate)
      / Event Type
      .mb-3
        %calm-select(v-model="editingApp.event_type" :options="eventTypeList()"
                     :placeholder="$t('comm.course_applications.attrs.event_type')" :maxHeight="250")
      / Date
      .mb-3.input-group
        %span.input-group-text {{ $t('datetime.prompts.year') }}
        %calm-select(v-model="editingApp.year" :options="yearList()" :maxHeight="200" )
        %span.input-group-text {{ $t('datetime.prompts.month') }}
        %calm-select(v-model="editingApp.month" :options="monthList()" :maxHeight="200" )
      / Location
      .mb-3
        -# %label.form-label Location
        %input.form-control(v-model="editingApp.venue" required
                            :placeholder="$t('comm.course_applications.attrs.location')")
      / Participation
      .mb-3(v-if="screen.isSM")
        %calm-select(:options="participationList()" v-model="editingApp.participation" label="label" track-by="value")
      .pt-1.mb-3.d-flex.justify-content-evenly(v-else)
        .form-check.form-check-inline(v-for="participation in participationList()")
          %input.form-check-input(v-model="editingApp.participation" :value="participation.value"
                                  type="radio" :id="participation.value" required)
          %label.form-check-label(:for="participation.value") {{ participation.label }}

      / PartTime
      .mb-3.input-group(v-if="isPartTime")
        %input.form-control(type="number" v-model="editingApp.part_time_duration"
                            :placeholder="$t('comm.course_applications.attrs.partime.how_many_days')")
        .input-group-text {{ $t('comm.course_applications.attrs.partime.days') }}
      / Teacher
      .mb-3.input-group
        %span.input-group-text {{ $t('comm.course_applications.attrs.teacher') }}
        %calm-select(v-model="editingApp.teacher" :options="teacherResults" label="name" :maxHeight="200"
                     open-direction="top" :loading="teacherLoading" v-on:search-change="searchTeacher"
                     :clearable="true")
      / Note
      .mb-3
        %input.form-control(type="text" v-model="editingApp.note"
                            :placeholder="$t('comm.course_applications.attrs.note')")

    %template(#footer="" v-if="editingApp")
      %button.btn.btn-default.me-3(@click="displayAppForm = false") {{ $t('lookups.actions.cancel')}}
      %button.btn.btn-primary(@click="submitApp" :disabled="!appIsValid")
        %template(v-if="editingApp.id") {{ $t('comm.lookups.actions.save') }}
        %template(v-else) {{ $t('comm.lookups.actions.add') }}
</template>

<script>
import { axios, handleAjaxError } from 'helpers/core/ajax-utils'
import Dialog from 'primevue/dialog'

export default {
  components: { Dialog },
  data() {
    return {
      displayAppForm: false,
      editingApp: null,
      teacherResults: [],
      teacherLoading: false
    }
  },
  computed: {
    title() {
      if (!this.editingApp) return ''
      return this.editingApp.id
        ? this.$t('comm.course_applications.headings.edit_course_title')
        : this.$t('comm.course_applications.headings.add_course_title')
    },
    appIsValid() {
      return this.editingApp.year && this.editingApp.participation
        && (!this.isPartTime || this.editingApp.part_time_duration)
        && this.editingApp.venue && this.editingApp.event_type
    },
    isPartTime() {
      return ['old_student_pt', 'server_pt'].includes(this.editingApp.participation)
    }
  },
  methods: {
    yearList() {
      const years = []
      for (let i = 1969; i <= new Date().getFullYear(); i++) years.push(i)
      return years
    },
    monthList() {
      return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((i) => [(new Date(2000, i)).toLocaleDateString(this.locale, { month: 'long' }), i])
    },
    participationList() {
      return ['old_student_ft', 'old_student_pt', 'server_ft', 'server_pt'].map((participation) => ({
        label: this.$t(`comm.course_applications.attrs.participation_values.${participation}`),
        value: participation
      }))
    },
    eventTypeList() {
      return this.$root.eventTypesForSelect
    },
    searchTeacher(query) {
      if (!query || query.length < 2) return
      this.teacherLoading = true
      axios.get(this.$routes.at_search_path({ format: 'json' }), {
        params: {
          term: query,
          include_ex_at: true,
          include_non_calm_at: true,
          limit: 30
        }
      }).then((result) => {
        this.teacherResults = result.data.results // .map(obj => obj.name)
        this.teacherLoading = false
      }).catch((error) => handleAjaxError(error, this))
    },
    editApp(app) {
      this.editingApp = { ...app } // clone app
      if (this.editingApp.start_date) {
        this.editingApp.month = this.editingApp.start_date.getMonth()
        this.editingApp.year = this.editingApp.start_date.getFullYear()
      }
      this.displayAppForm = true
    },
    submitApp() {
      this.displayAppForm = false
      if (this.editingApp.year) this.editingApp.start_date = new Date(this.editingApp.year, this.editingApp.month || null, 10) // init to day 10, so we don't have issue with time zone. cause January 1st 00:00 at GMT -5 = December 31 at GMT 0

      if (!this.editingApp.id) {
        this.$root.create('apps', this.editingApp)
      } else {
        this.$root.update('apps', this.editingApp)
      }
    }
  }
}
</script>
