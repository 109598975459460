<template lang='haml'>
  .reply-segment
    %strong.text-success(v-if="action == 'show'")
      {{ segment.value || $t("lookups.transmission.reply_segment.no_answer") }}
    %textarea.form-control(v-else
      :value="value"
      :name="`form[segments][${segment.ident}]`"
      :required="!!segment.required"
      :disabled="action == 'preview'"
      @input="$emit('input', $event.target.value)"
      :rows="segment.rows")
      {{ segment.value }}
</template>

<script>
export default { props: ['segment', 'action', 'value'] }
</script>

<style lang='scss' scoped>
  .reply-segment {
    margin-top: .5rem;
  }
</style>
